import React from "react";
import PropTypes from "prop-types";

/**
 * ProgressBar
 *
 * Progress bar component that we can animate.
 *
 * @param {*} props
 * @returns
 */
const ProgressBar = (props) => {
  const { maxSteps, currentStep } = props;

  return (
    <div className="ProgressBar">
      <div
        className={`ProgressBar__inner ${
          currentStep >= maxSteps ? "--full" : ""
        }`}
        style={{ maxWidth: `${(currentStep / maxSteps) * 100}%` }}
      ></div>
    </div>
  );
};

ProgressBar.propTypes = {
  maxSteps: PropTypes.number.isRequired,
  currentStep: PropTypes.number.isRequired,
};

ProgressBar.defaultProps = {
  maxSteps: 100,
  currentStep: 0,
};

export default ProgressBar;
