import CloseConfirm from "./Screens/CloseConfirm";
import { css, StyleSheet } from "aphrodite";
import Modal from "react-modal";
import PropTypes from "prop-types";
import React from "react";
import { genericFunnels } from "~/config/funnel";

/**
 * MessageAgentModal
 *
 * Message agent modal component.
 *
 * @param {*} props
 * @returns
 */
const MessageAgentModal = (props) => {
  const {
    children,
    confirmClose,
    isOpen,
    largeModal,
    onRequestClose,
    agent,
    funnel,
    className,
    useContactForm,
    agentPage,
  } = props;

  return (
    <Modal
      bodyOpenClassName="MessageAgentModal__open"
      className={`MessageAgentModal ${largeModal ? "--large" : ""} ${
        genericFunnels.includes(funnel) && css(styles.modal)
      }`}
      closeTimeoutMS={200}
      contentLabel={"message agent"}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="MessageAgentModal__overlay"
    >
      <div
        className={`MessageAgentModal__container ${
          agentPage ? "--agent-page" : ""
        } ${useContactForm ? css(styles.container) : ""}`}
        onClickCapture={(e) => {
          if (e.target === e.currentTarget) {
            onRequestClose(e, useContactForm ? false : largeModal);
          }
        }}
      >
        <div
          className={`MessageAgentModal__body ${
            agentPage ? "--agent-page" : ""
          } ${className ? className : ""}`}
        >
          {confirmClose ? (
            <CloseConfirm
              onRequestClose={onRequestClose}
              agent={agent}
              funnel={funnel}
            />
          ) : null}
          {children}
        </div>
      </div>
    </Modal>
  );
};

const styles = StyleSheet.create({
  modal: {
    height: "80%",
  },
  container: {
    height: "100%",
  },
});

MessageAgentModal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.node,
    PropTypes.object,
  ]),
  confirmClose: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  largeModal: PropTypes.bool,
  onRequestClose: PropTypes.func.isRequired,
};

MessageAgentModal.defaultProps = {
  children: [],
  confirmClose: false,
  isOpen: false,
  largeModal: false,
  onRequestClose: () => {},
};

export default MessageAgentModal;
