import { StyleSheet, css } from "aphrodite";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { emailRegexString } from "~/utils/Constants/RegexStrings";

/**
 * Input
 *
 * Input component.
 *
 * @param {*} props
 * @returns
 */
const Input = (props) => {
  const {
    autoComplete,
    autoFocus,
    className,
    customErrorMessage,
    helpText,
    label,
    maxLength,
    name,
    onChange,
    pattern,
    required,
    type,
    value,
    checkValidation,
    passwordMatches,
    minLength,
    onFocus,
    inputStyle,
    inputError,
    inputValueRestrictions,
    inputRef,
    onKeyDown,
    showTopLabel,
    noActiveLabel,
    placeholder,
    inlineFunnel,
  } = props;

  const [id, setID] = useState(name);
  const [active, setActive] = useState(() => {
    if (value !== undefined && value.length >= 1) return true;
    return false;
  });
  const [firstFocus, setFirstFocus] = useState(autoFocus);
  const [internalValue, setInternalValue] = useState(value || "");
  const [valid, setValid] = useState(true);
  const [errors, setErrors] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [inputPattern, setInputPattern] = useState(null);

  const formatPattern = (pattern) => {
    if (pattern == null) {
      return pattern;
    }

    if (pattern[0] === "/" && pattern[pattern.length - 1] === "/") {
      pattern = pattern.slice(1, -1);
    }
    return pattern;
  };

  useEffect(() => {
    let tempPattern = null;
    if (pattern) {
      tempPattern = pattern;
    } else if (type === "email") {
      tempPattern = emailRegexString;
    }
    setInputPattern(tempPattern);
  }, [pattern]);

  useEffect(() => {
    setInternalValue(value);
    if (!firstFocus) {
      setActive(() => {
        if (value !== undefined && value.length >= 1) return true;
        return false;
      });
    }
  }, [value]);

  useEffect(() => {
    if (inputRef?.current && checkValidation) {
      if (!isValid(inputRef.current)) {
        setValid(false);
        errorMessages(inputRef.current);
      } else {
        setValid(true);
        setErrors([]);
      }
    }
  }, [checkValidation, internalValue]);
  /**
   * errorMessages
   *
   * adds error messages if input is invalid.
   *
   * @param {Element} el
   */
  const errorMessages = (el) => {
    console.log(el);
    console.log(el.pattern);

    let errorMessages = [];
    if (el.validity.valueMissing) {
      errorMessages.push({
        message: `Please enter your ${label.toLowerCase()}`,
        type: "valueMissing",
      });
    }

    if (el.value && el.validity.typeMismatch && inputPattern === null) {
      errorMessages.push({
        message: `Please enter a ${label.toLowerCase()}`,
        type: "typeMismatch",
      });
    }

    if (el.value && el.validity.patternMismatch) {
      errorMessages.push({
        message: `Please enter a valid ${label.toLowerCase()}`,
        type: "patternMismatch",
      });
    }

    if (el.value && el.validity.tooShort) {
      errorMessages.push({
        message: `${label} Must be at least ${minLength} characters in length`,
        type: "tooShort",
      });
    }

    if (type == "password" && !passwordMatches) {
      errorMessages.push({
        message: `Passwords do not match`,
        type: "patternMismatch",
      });
    }

    errorMessages = customErrorMessage({
      errorMessages,
      el,
      pattern: inputPattern,
      label,
      type,
      name,
    });
    setErrors(errorMessages);
  };

  const isValid = (target) => {
    if (type === "password" && !passwordMatches) return false;
    if (!target.value) return true;
    return target.validity.valid;
  };

  const handleBlur = (e) => {
    if (internalValue?.length <= 0 && !firstFocus) {
      setActive(false);
    } else {
      setFirstFocus(false);
    }
    if (!isValid(e.target)) {
      setValid(false);
      errorMessages(e.target);
    } else {
      setValid(true);
      setErrors([]);
    }
  };

  const handleFocus = (e) => {
    onFocus(e);
    if (!active) {
      setActive(true);
    }
  };

  const handleChange = (e) => {
    let inputValue = inputValueRestrictions(e.target.value, name);

    if (pattern && !pattern.test(e.target.value)) {
      return;
    }

    setInternalValue(inputValue);

    if (isValid(e.target)) {
      setValid(true);
      setErrors([]);
    }

    onChange(inputValue);
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div>
      {showTopLabel && <div className={css(styles.topLabel)}>{label}</div>}
      <div className={`Input ${className}`}>
        <div className="Input__wrapper">
          {label && !noActiveLabel && (
            <label
              htmlFor={id}
              className={`${inlineFunnel && !active ? "--inline-funnel" : ""} ${
                active ? "--active" : ""
              }`}
            >
              {label}
            </label>
          )}
          <input
            id={id}
            className={
              `${
                !valid || (inputError && inputError.input === name)
                  ? "--invalid"
                  : internalValue?.length >= 1
                  ? "--valid"
                  : ""
              } ${inputStyle} ` + css(noActiveLabel && styles.noActiveLabel)
            }
            autoComplete={autoComplete}
            autoFocus={autoFocus}
            maxLength={maxLength}
            minLength={minLength}
            name={name}
            onBlur={handleBlur}
            onChange={handleChange}
            onFocus={handleFocus}
            pattern={formatPattern(inputPattern?.toString())}
            required={required}
            placeholder={placeholder}
            type={showPassword ? "text" : type}
            value={internalValue}
            ref={inputRef}
            onKeyDown={onKeyDown}
          />
          {type === "password" && (
            <button
              className={`Input__password-toggle ${
                showPassword ? "--active" : ""
              }`}
              onClick={handleShowPassword}
              tabIndex={"-1"}
              type="button"
            >
              Show password
            </button>
          )}
        </div>
        {helpText && <div className="Input__help-text">{helpText}</div>}
        {errors && errors.length > 0 && (
          <ul className="Input__errors">
            {errors.map((error, index) => (
              <li key={index}>{error.message}</li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

const styles = StyleSheet.create({
  topLabel: {
    marginBottom: 8,
    fontWeight: 500,
  },
  noActiveLabel: {
    padding: "20px 15px 20px 15px",
    boxShadow: "rgba(30, 41, 59, 0.2) 0px 0px 28px",
    border: 0,
    background: "#fff",
  },
});

Input.propTypes = {
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  customErrorMessage: PropTypes.func,
  helpText: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  maxLength: PropTypes.string,
  minLength: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  pattern: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.string,
  passwordMatches: PropTypes.bool,
  onFocus: PropTypes.func,
  inputValueRestrictions: PropTypes.func,
  checkValidation: PropTypes.bool,
  inputRef: PropTypes.object,
  onKeyDown: PropTypes.func,
};

Input.defaultProps = {
  autoComplete: "off",
  autoFocus: null,
  className: "",
  customErrorMessage: ({ errorMessages }) => {
    return errorMessages;
  },
  inputValueRestrictions: (inputValue, name) => {
    let newInputValue = inputValue.replace(
      /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g,
      ""
    );

    if (name.includes("name")) {
      newInputValue = newInputValue.replace(/[^\p{L}\s\.',-]/gu, "");
    }

    return newInputValue;
  },
  helpText: null,
  id: null,
  label: "",
  maxLength: null,
  minLength: null,
  name: null,
  onChange: () => {},
  pattern: null,
  required: null,
  type: "text",
  value: "",
  checkValidation: false,
  passwordMatches: true,
  onFocus: () => {},
  inputRef: null,
  onKeyDown: () => {},
};

export default Input;
