import { reaFunnelRequests } from "~/utils/Fetch/Analytics";
import { clearLeadUUID, getLeadUUID } from "~/utils/UUID";
import { postAnalytics } from "~/utils/FetchingData";
import {
  exportPageType,
  exportPageVersion,
  queryParams,
} from "../../../utils/Fetch/Analytics";

/**
 * formatAnalytics
 *
 * Maps Analytics keys and values
 *
 * @param {*} param0
 * @returns
 */
const formatAnalytics = ({ key, value }) => {
  let analytics = {
    funnel_step: null,
    parameters: {},
  };
  value = value || {};

  switch (key) {
    case "ContactForm":
      analytics.funnel_step_index = 1;
      analytics.funnel_step = "Client Information";
      analytics.parameters["name"] = value.user?.name || null;
      analytics.parameters["email"] = value.user?.email || null;
      analytics.parameters["phone_number_input"] =
        value.user?.phonenumber || null;
      analytics.parameters = { ...analytics.parameters, ...value.lead };
      break;
    case "AgentUnavailable":
      analytics.funnel_step_index = 1;
      analytics.funnel_step = "Agent Unavailable";
      break;
    case "MissedConnection":
      analytics.funnel_step_index = 2;
      analytics.funnel_step = "Missed Connection";
      break;
    case "ClientType":
      analytics.funnel_step_index = 3;
      analytics.funnel_step = "Lead Type";
      analytics.parameters["lead_type"] = value.clientType || null;
      break;
    case "PropertyType":
      analytics.funnel_step_index = 4;
      analytics.funnel_step = "Property Type";
      analytics.parameters["type_home"] = value.propertyType || null;
      break;
    case "ClientTimeFrame":
      analytics.funnel_step_index = 5;
      analytics.funnel_step = "Time Frame";
      analytics.parameters["time_frame"] = value.clientTimeFrame || null;
      break;
    case "PropertyAddress":
      analytics.funnel_step_index = 6;
      analytics.funnel_step = "Lead Location";
      analytics.parameters = value.addressComponents || {};
      analytics.parameters["unit_number"] = value.propertyAptNumber || null;
      break;
    case "PriceRange":
      analytics.funnel_step_index = 7;
      analytics.funnel_step = "Price";
      analytics.parameters["home_price_min"] = value.min || null;
      analytics.parameters["home_price_max"] = value.max || null;
      break;
    case "ClientAlsoSelling":
      analytics.funnel_step_index = 8;
      analytics.funnel_step = "Lead Also Selling";
      analytics.parameters["lead_also_selling"] =
        value.clientAlsoSelling || null;
      break;
    case "ClientAlreadyHaveAnAgent":
      analytics.funnel_step_index = 9;
      analytics.funnel_step = "Lead Already Have an Agent";
      analytics.parameters["lead_has_agent"] =
        value.clientAlreadyHaveAnAgent || null;
      break;
    case "ClientNeeds":
      analytics.funnel_step_index = 10;
      analytics.funnel_step = "Lead Message";
      analytics.parameters["lead_message"] = value.clientNeeds;
      break;
    case "ClientContactInformation":
      analytics.funnel_step_index = 11;
      analytics.funnel_step = "Lead Contact Information";
      analytics.parameters["name"] = value.name || null;
      analytics.parameters["email"] = value.email || null;
      analytics.parameters["phone_number_input"] = value.phonenumber || null;
      analytics.parameters["other_agents"] = value.other_agents || null;
      break;
    case "Complete":
      analytics.funnel_step_index = 12;
      analytics.funnel_step = "Completed";
      break;
    case "ComfimClose":
      analytics.funnel_step_index = 13;
      analytics.funnel_step = "Comfirm Close";
      break;
    case "TwoFA":
      analytics.funnel_step_index = 14;
      analytics.funnel_step = "2FA Screen";
    case "TwoFACode":
      analytics.funnel_step_index = 15;
      analytics.funnel_step = "2FA Code Entry";
      break;
  }

  return analytics;
};

/**
 * stepLoaded
 *
 * Sends analytics when step is loaded
 *
 * @param {*} param
 * @param {*} router
 */
export const stepLoaded = (
  { key, value },
  router,
  agentStatus,
  funnel,
  pageVersion = null
) => {
  let analytics = formatAnalytics({ key, value });
  analytics.parameters = null;
  const sessionData =
    JSON.parse(sessionStorage.getItem("message_agent_session")) || {};
  sessionStorage.setItem(
    "message_agent_session",
    JSON.stringify({
      ...sessionData,
    })
  );
  reaFunnelRequests({
    ...analytics,
    session_uuid: window.localStorage.getItem("rea_session_uuid"),
    page: router.asPath.split("?")[0],
    funnel_version: funnel,
    request_type: "GET",
    page_type: exportPageType(router.pathname),
    page_version:
      pageVersion || exportPageVersion(router.pathname, agentStatus),
    session:
      JSON.parse(sessionStorage.getItem("message_agent_session")) || null,
  });
};

/**
 * postFunnelAnalytics
 *
 * Sends analytics when step is complete
 *
 * @param {*} param0
 * @param {*} router
 */
export const postFunnelAnalytics = (
  { key, value },
  router,
  agentStatus,
  funnel,
  clearUUID,
  pageVersion = null
) => {
  let analytics = formatAnalytics({ key, value });
  const sessionData =
    JSON.parse(sessionStorage.getItem("message_agent_session")) || {};
  sessionStorage.setItem(
    "message_agent_session",
    JSON.stringify({
      ...sessionData,
      ...analytics.parameters,
    })
  );
  let uuid = null;
  if (key === "ClientContactInformation" || key === "ContactForm") {
    uuid = getLeadUUID();
  }
  reaFunnelRequests({
    ...analytics,
    session_uuid: window.localStorage.getItem("rea_session_uuid"),
    lead_uuid:
      key === "ClientContactInformation" || "ContactForm" ? uuid : null,
    page: router.asPath.split("?")[0],
    funnel_version: funnel,
    request_type: "POST",
    page_type: exportPageType(router.pathname),
    page_version:
      pageVersion || exportPageVersion(router.pathname, agentStatus),
    session:
      JSON.parse(sessionStorage.getItem("message_agent_session")) || null,
  });

  if (key === "Complete" || clearUUID) {
    clearLeadUUID();
  }
};

/**
 * postEvent
 *
 * Sends analytics event
 *
 * @param {*} action
 * @param {*} router
 */
export const postEvent = (
  action,
  router,
  agentStatus,
  funnel,
  pageVersion = null
) => {
  postAnalytics({
    action: action,
    action_details: {
      url: router.asPath,
      params: router.query,
    },
    funnel: funnel,
    page_type: exportPageType(router.pathname),
    page_version:
      pageVersion || exportPageVersion(router.pathname, agentStatus),
    query_parameters: queryParams(),
    lead_uuid: router.query.lead_uuid,
    session_uuid: window.localStorage.getItem("rea_session_uuid"),
  });

  if (action === "message_agent_completed") {
    clearLeadUUID();
  }
};

export const resetSession = () => {
  sessionStorage.removeItem("message_agent_session");
};
