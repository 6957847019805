import Link from "next/link";
import MegaMenu from "@referralexchange/rea-ui-react/components/navigation/MegaMenu";
import HeadingWithLinks from "@referralexchange/rea-ui-react/components/navigation/HeadingWithLinks";
import Content from "@referralexchange/rea-ui-react/atoms/Content";

import YardSign from "@referralexchange/rea-ui-react/svgs/yard-sign";
import BookOpenIcon from "@referralexchange/rea-ui-react/svgs/book-open";
import BubbleWithQuestionMarkIcon from "@referralexchange/rea-ui-react/svgs/bubble-with-question-mark";
import HouseForSaleIcon from "@referralexchange/rea-ui-react/svgs/nav/house-for-sale";
import MagnifyingGlassIcon from "@referralexchange/rea-ui-react/svgs/magnifying-glass";

import CtaButton from "~/components/CtaButton";
import { MEGA_MENU_SELLER } from "~/config/funnel";

export default function SellerMegaMenu() {
  const leftCTA = (
    <div className={MegaMenu.styles.cta}>
      <HouseForSaleIcon title="Selling a Home Icon" />
      <Content className={Content.styles.medium}>Selling a Home?</Content>
      <Content
        className={[Content.styles.body14, Content.styles.center].join(" ")}
      >
        Find your perfect local real estate agent
      </Content>
      <CtaButton funnel={MEGA_MENU_SELLER} type="selling">
        Connect Today
      </CtaButton>
    </div>
  );

  const centerItems = [
    <Link href="/working-with-an-agent">Working with a Real Estate Agent</Link>,
    <Link href="/how-to-find-a-real-estate-agent">
      How to Find a Real Estate Agent
    </Link>,
    <Link href="/how-to-compare-real-estate-agents">
      How to Compare Real Estate Agents
    </Link>,
    <Link href="/how-a-real-estate-agent-works-for-you">
      How a Real Estate Agent Works for You
    </Link>,
  ];

  const rightItems = [
    <Link href="/sell">
      <span
        className={[
          HeadingWithLinks.styles.icon,
          HeadingWithLinks.styles.iconFillAndStroke,
        ].join(" ")}
      >
        <YardSign />
      </span>
      Find a Seller&apos;s Agent
    </Link>,
    <Link href="/blog/selling">
      <span className={HeadingWithLinks.styles.icon}>
        <BookOpenIcon />
      </span>
      Seller&apos;s Blog
    </Link>,
    <Link href="/faq/for-sellers">
      <span className={HeadingWithLinks.styles.icon}>
        <BubbleWithQuestionMarkIcon />
      </span>
      Selling Your Home FAQ
    </Link>,
    <Link href="/search">
      <span className={HeadingWithLinks.styles.icon}>
        <MagnifyingGlassIcon />
      </span>
      Search by Location
    </Link>,
  ];

  return (
    <MegaMenu
      label="Sellers"
      left={leftCTA}
      center={
        <HeadingWithLinks heading={"Seller's Guides"} items={centerItems} />
      }
      right={
        <HeadingWithLinks heading={"Seller's Resources"} items={rightItems} />
      }
    />
  );
}
