import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from "../translations/en-US.json";
import fr from "../translations/fr.json";

const resources = {
  "en-US": {
    translation: en,
  },
  fr: {
    translation: fr,
  },
};

i18n.use(initReactI18next).init({
  lng: "en-US",
  debug: false,
  resources,
});

export default i18n;
