import { useEffect, useRef, useState } from "react";
import Error from "next/error";

// NPM Modules
import { connect } from "react-redux";
import { StyleSheet, css } from "aphrodite";
import "isomorphic-unfetch";
import TagManager from "react-gtm-module";
import dynamic from "next/dynamic";
import Head from "../components/Meta/Head";

import { ErrorActions } from "~/redux/error";
import { PlacesSearchActions } from "~/redux/placesSearch";
import { createUUID } from "../utils/UUID";
import { calculateBreadcrumbSchema } from "../utils/Breadcrumbs";

import { MessageActions } from "~/redux/message";
import DefaultLayout from "~/components/layouts/Default";

const Message = dynamic(import("~/components/Message"));

const isError = (error) => {
  return error.statusCode < 200 || error.statusCode > 300;
};

const Base = (props) => {
  const {
    Component,
    pageProps,
    error,
    setError,
    router,
    setSearchValue,
    leadUUID,
    showMessage,
  } = props;
  const [hasTouch, setHasTouch] = useState(false);
  const [showPrime, setShowPrime] = useState(false);

  const firstEffect = useRef(true);
  const showLoader = useRef();
  const getLayout =
    Component.getLayout || ((page) => <DefaultLayout>{page}</DefaultLayout>);

  useEffect(() => {
    router.events.on("routeChangeStart", (url) => {
      if (showLoader.current != null) {
        clearTimeout(showLoader.current);
      }
      showLoader.current = setTimeout(() => {
        showMessage({ show: true, load: true });
      }, 200);
    });

    router.events.on("routeChangeComplete", (url) => {
      clearTimeout(showLoader.current);
      showMessage({ show: false });
    });

    router.events.on("routeChangeError", () => {
      clearTimeout(showLoader.current);
      showMessage({ show: false });
    });
  }, [router.events]);

  const calculateError = () => {
    let errorObj = {};
    if (pageProps && pageProps.statusCode) {
      errorObj = { statusCode: pageProps.statusCode };
    } else {
      errorObj = error;
    }
    let metadata = { ...pageProps.metadata };

    if (!metadata.breadcrumbJson) {
      let pathnameArray = router.pathname.split("/");
      pathnameArray.shift();
      let pathArray = router.asPath.split("/");
      pathArray.shift();

      if (router.pathname === "/") {
        pathnameArray = [];
        pathArray = [];
      }
      const breadcrumbJson = calculateBreadcrumbSchema({
        pathnameArray,
        pathArray,
        agent: pageProps.pageData && pageProps.pageData.agent,
        city:
          pageProps.pageData &&
          pageProps.pageData.location &&
          pageProps.pageData.location.city,
        state:
          pageProps.pageData &&
          pageProps.pageData.location &&
          pageProps.pageData.location.state,
      });
      metadata = { ...metadata, breadcrumbJson };
    }
    return (
      <>
        {isError(errorObj) ? (
          <Error statusCode={errorObj.statusCode} />
        ) : (
          <div id={"contentContainer"} className={css(styles.contentContainer)}>
            <Head {...metadata} />
            {getLayout(<Component {...pageProps} />)}
          </div>
        )}
      </>
    );
  };

  useEffect(() => {
    const url = new URL(window.location.href);
    if (!url.searchParams.has("no-gtag")) {
      TagManager.initialize({
        gtmId: "GTM-PXNTZ86",
      });
    }
    createUUID({});
    setHasTouch(isTouchDevice());

    if (window.location.search) {
      window.localStorage.setItem("loadedQueryParams", window.location.search);
    }
  }, []);

  useEffect(() => {
    if (!firstEffect.current) {
      if (isError(error)) {
        setError({ statusCode: 200 });
      }
    } else {
      firstEffect.current = false;
    }

    if (isError(error) || isError({ statusCode: pageProps.statusCode })) {
      setSearchValue("");
    }

    setShowPrime(
      router.query.force_experience === "prime" ||
        pageProps?.pageData?.agent?.prime
    );
  }, [router.asPath]);

  function isTouchDevice() {
    return (
      "ontouchstart" in window ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0
    );
  }

  return (
    <div
      id={"pageWrapper"}
      className={css(
        styles.pageWrapper,
        hasTouch && styles.touchDevice,
        router.pathname.includes("/[agent_slug]") &&
          showPrime &&
          styles.agentPageMobileSticky
      )}
    >
      {calculateError()}
      <Message />
    </div>
  );
};

const styles = StyleSheet.create({
  pageWrapper: {
    width: "100%",
    minHeight: "100vh",
    background: "#fff",
  },
  agentPageMobileSticky: {
    "@media only screen and (max-width: 1023px)": {
      paddingBottom: 140,
    },
  },
  touchDevice: {
    "@media only screen and (max-width: 767px)": {
      cursor: "pointer",
    },
  },
  sticky: {
    zIndex: 2,
  },
  stickContainer: {
    zIndex: 10,
    position: "absolute",
    width: "100%",
  },
  contentContainer: {
    position: "relative",
  },
});

const mapStateToProps = (state) => ({
  error: state.error,
});

const mapDispatchToProps = {
  setError: ErrorActions.setError,
  setSearchValue: PlacesSearchActions.setSearchValue,
  showMessage: MessageActions.showMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(Base);
