/***
 * Action / Reducer file for places searching
 */

/**********************************
 *        ACTIONS SECTION         *
 **********************************/

export const PlacesSearchConstants = {
  SET_SEARCH_VALUE: "@@placesSearch/SET_SEARCH_VALUE",
  SET_LOCATION_COORDINATES: "@@placesSearch/SET_LOCATION_COORDINATES",
  SET_USER_TYPE: "@@placesSearch/SET_USER_TYPE",
  SET_PRICE_RANGE: "@@placesSearch/SET_PRICE_RANGE",
  SET_SEARCH_SIGNAL: "@@placesSearch/SET_SEARCH_SIGNAL",
  SET_FIRST_SEARCH: "@@placesSearch/SET_FIRST_SEARCH",
};

export const PlacesSearchActions = {
  /***
   * set search value
   * @value {String} -- location to set as search value
   */
  setSearchValue: (value) => {
    return (dispatch, getStore) => {
      return dispatch({
        type: PlacesSearchConstants.SET_SEARCH_VALUE,
        searchValue: value,
      });
    };
  },

  /**
   * set first search value to true or false
   */
  setFirstSearch: (firstSearch) => {
    return (dispatch) => {
      return dispatch({
        type: PlacesSearchConstants.SET_FIRST_SEARCH,
        firstSearch: firstSearch,
      });
    };
  },

  /***
   * set location coordinates of searched location
   * @coordinates {Object} -- lat and lng of searched location returned from google
   */
  setLocationCoordinates: (coordiantes) => {
    return (dispatch, getStore) => {
      return dispatch({
        type: PlacesSearchConstants.SET_LOCATION_COORDINATES,
        locationCoordinates: coordiantes,
      });
    };
  },

  /***
   * set user type
   * @userType {String} -- if the user is a buyer or a seller
   */
  setUserType: (userType) => {
    return (dispatch) => {
      return dispatch({
        type: PlacesSearchConstants.SET_USER_TYPE,
        userType: userType,
      });
    };
  },

  /***
   * set price range
   * @priceRange {Object} -- min and max for price range
   */
  setPriceRange: (priceRange) => {
    return (dispatch) => {
      return dispatch({
        type: PlacesSearchConstants.SET_PRICE_RANGE,
        price: priceRange,
      });
    };
  },

  /***
   * set search signal
   * @searchSignal {Boolean} -- true of false is we should search
   */
  setSearchSignal: (searchSignal) => {
    return (dispatch) => {
      return dispatch({
        type: PlacesSearchConstants.SET_SEARCH_SIGNAL,
        searchSignal,
      });
    };
  },
};

/**********************************
 *        REDUCER SECTION         *
 **********************************/

const defaultPlacesSearchState = {
  searchValue: "",
  locationCoordinates: { lat: 0, lng: 0 },
  userType: {
    label: null,
    value: null,
  },
  price: {
    max: 0,
    min: 0,
  },
  searchSignal: false,
  firstSearch: true,
};

const PlacesSearchReducer = (state = defaultPlacesSearchState, action) => {
  switch (action.type) {
    case PlacesSearchConstants.SET_SEARCH_VALUE:
    case PlacesSearchConstants.SET_LOCATION_COORDINATES:
    case PlacesSearchConstants.SET_USER_TYPE:
    case PlacesSearchConstants.SET_PRICE_RANGE:
    case PlacesSearchConstants.SET_SEARCH_SIGNAL:
    case PlacesSearchConstants.SET_FIRST_SEARCH:
      return {
        ...state,
        ...action,
      };
    default:
      return state;
  }
};

export default PlacesSearchReducer;
