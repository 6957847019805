import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import TextArea from "~/components/Inputs/TextArea";
import { capitalize } from "../../../../utils/TextProcessing";
import { genericFunnels } from "~/config/funnel";
/**
 * ClientNeeds
 *
 * Client needs screen. Collects user's message to the agent(s).
 *
 * @param {*} props
 * @returns
 */
const ClientNeeds = (props) => {
  const {
    clientNeeds,
    setClientNeeds,
    headingProp,
    placeholderProp,
    nextScreen,
    prevScreen,
    claimed,
    agent,
    screensSeen,
    notRequired,
    funnel,
    noButtons,
  } = props;
  const screenKey = "ClientNeeds";

  const [nextButtonTitle, setNextButtonTitle] = useState(() => {
    if (notRequired) {
      return null;
    }
    if (!clientNeeds?.length) {
      return "Message is required";
    }
    return null;
  });

  const messageInputRef = useRef();

  useEffect(() => {
    // NOTE: Timeout is used to stop the header from re-rendering
    if (screensSeen.has(screenKey))
      setTimeout(() => {
        // NOTE: These should be swapped out to use a ref
        let clientNeeds = document.querySelector(
          ".MessageAgentForm [name='client-needs']"
        );
        clientNeeds.focus();
        clientNeeds.blur();
      }, 100);
  }, []);

  const customErrorMessage = ({ el }) => {
    let errorMessages = [];
    if (el.validity.valueMissing && !notRequired) {
      errorMessages.push({
        message: "Message is required",
        type: "valueMissing",
      });
    }

    if (el.validity.typeMismatch && pattern === undefined) {
      errorMessages.push({
        message: "Please enter a message",
        type: "typeMismatch",
      });
    }

    if (el.validity.patternMismatch) {
      errorMessages.push({
        message: "Please enter a valid message",
        type: "patternMismatch",
      });
    }

    return errorMessages;
  };

  const handleChange = (value) => {
    if (messageInputRef.current.validity.valid) {
      setNextButtonTitle(null);
    } else {
      if (!notRequired) {
        const errorMessages = customErrorMessage({
          el: messageInputRef.current,
        });
        setNextButtonTitle(errorMessages[0].message);
      }
    }
    setClientNeeds(value);
  };

  //set context dependent text strings
  const heading = headingProp
    ? headingProp
    : !claimed
    ? "Write your message to agents"
    : `Write your message to ${capitalize(
        agent.display_name.split(/(\s+)/)[0]
      )}`;
  const placeholder = placeholderProp
    ? placeholderProp
    : !claimed
    ? "Enter your message to agents"
    : `Enter your message to ${capitalize(
        agent.display_name.split(/(\s+)/)[0]
      )}`;

  return (
    <div className="MessageAgentForm__screen" key="needs">
      <div className="MessageAgentForm__screen-heading">{heading}</div>
      <TextArea
        label={placeholder}
        onChange={handleChange}
        name={"client-needs"}
        value={clientNeeds}
        required={!notRequired}
        maxlength="20000"
        customErrorMessage={customErrorMessage}
        inputRef={messageInputRef}
      />
      {noButtons ? null : (
        <div className="MessageAgentForm__screen-controls">
          <button
            className="--alt"
            type="button"
            onClick={() => {
              prevScreen({ key: screenKey });
            }}
          >
            Back
          </button>
          <button
            type="button"
            onClick={() => {
              nextScreen({ key: screenKey });
            }}
            disabled={!notRequired && !clientNeeds}
            title={nextButtonTitle}
          >
            {genericFunnels.includes(funnel) && !clientNeeds ? "Skip" : "Next"}
          </button>
        </div>
      )}
    </div>
  );
};

ClientNeeds.propTypes = {
  clientNeeds: PropTypes.string,
  setClientNeeds: PropTypes.func.isRequired,
  nextScreen: PropTypes.func.isRequired,
  prevScreen: PropTypes.func.isRequired,
  agent: PropTypes.object.isRequired,
  claimed: PropTypes.bool.isRequired,
  screensSeen: PropTypes.object.isRequired,
};

ClientNeeds.defaultProps = {
  clientNeeds: undefined,
  setClientNeeds: () => {},
  nextScreen: () => {},
  prevScreen: () => {},
  agent: {},
  claimed: false,
  screensSeen: new Set(),
};

export default ClientNeeds;
