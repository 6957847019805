import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Error = (props) => {
  const { onRequestClose } = props;

  const style = {
    color: "rgba(163, 38, 0, 1)",
    marginBottom: "20px",
  };

  return (
    <div className="MessageAgentForm__complete">
      <div className="MessageAgentForm__complete-header">
        <FontAwesomeIcon
          icon={["fal", "exclamation-circle"]}
          style={style}
          size="5x"
        />
        <div className="MessageAgentForm__complete-heading">Error</div>
      </div>
      <div className="MessageAgentForm__complete-sub-heading">
        we are unable to complete your request at this time.
      </div>
      <div className="MessageAgentForm__complete-sub-heading">
        Please try again later!
      </div>
      <div className="MessageAgentForm__screen-controls">
        <button
          type="button"
          onClick={() => {
            onRequestClose();
          }}
        >
          Close
        </button>
      </div>
    </div>
  );
};

Error.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
};

Error.defaultProps = {
  onRequestClose: () => {},
};

export default Error;
