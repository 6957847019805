import { css, StyleSheet } from "aphrodite";
import Image from "next/legacy/image";
import { useEffect, useState } from "react";

const ImageComp = (props) => {
  let {
    defaultSrc,
    imageSrc,
    className,
    alt,
    containerClassName,
    priority,
  } = props;
  const replaceImgWithError = (e) => {
    setSrc(defaultSrc);
  };

  const [src, setSrc] = useState(imageSrc);

  useEffect(() => {
    setSrc(imageSrc);
  }, [imageSrc]);

  return (
    <div
      className={`${css(styles.imageContainer)} ${
        containerClassName ? containerClassName : ""
      }`}
    >
      <Image
        className={`${css(styles.image)} ${className ? className : ""}`}
        onError={replaceImgWithError}
        placeholder={defaultSrc ? "blur" : null}
        blurDataURL={defaultSrc || null}
        alt={alt}
        src={src || defaultSrc}
        layout="fill"
        priority={priority}
      />
    </div>
  );
};
const styles = StyleSheet.create({
  image: {
    objectFit: "contain",
    background: "transparent",
  },
  imageContainer: {
    position: "relative",
  },
});
export default ImageComp;
