import numeral from "numeral";

export const capitalize = (str, seperators) => {
  if (str) {
    let tempStr = str.toLowerCase().split(" ");

    for (let i = 0; i < tempStr.length; i++) {
      tempStr[i] = tempStr[i].charAt(0).toUpperCase() + tempStr[i].slice(1);
    }
    tempStr = tempStr.join(" ");

    tempStr = tempStr.split("-");

    for (let i = 0; i < tempStr.length; i++) {
      tempStr[i] = tempStr[i].charAt(0).toUpperCase() + tempStr[i].slice(1);
    }

    tempStr = tempStr.join("-");
    if (seperators && seperators.length > 0) {
      for (let i = 0; i < seperators.length; i++) {
        tempStr = tempStr.split(seperators[i]);

        for (let j = 0; j < tempStr.length; j++) {
          tempStr[j] = tempStr[j].charAt(0).toUpperCase() + tempStr[j].slice(1);
        }
        tempStr = tempStr.join(seperators[i]);
      }
    }

    return tempStr;
  } else {
    return null;
  }
};

export function formatURLParam(param) {
  return param?.replace(/ /g, "-").toLowerCase();
}

export function splitName(name) {
  if (!name) {
    return { firstName: null, lastName: null };
  }
  const nameArray = name?.split(" ");
  const firstName = nameArray[0];
  const lastName = nameArray[nameArray.length - 1];
  return { firstName, lastName };
}

export function formatNumber({ data, type }) {
  let tempData = "0";
  if (type === "currency") {
    if (data < 100000) {
      tempData = "<$100k";
    } else if (data < 1000000) {
      tempData = numeral(data).format("$0a").toUpperCase();
    } else {
      tempData = numeral(data).format("$0.00a").toUpperCase();
    }
  } else {
    if (data < 10000) {
      tempData = numeral(data).format("0,0").toUpperCase();
    } else if (data < 1000000) {
      tempData = numeral(data).format("0a").toUpperCase();
    } else if (data < 10000000) {
      tempData = numeral(data).format("0.0a").toUpperCase();
    } else {
      tempData = numeral(data).format("0a").toUpperCase();
    }
  }

  return tempData;
}

const base64UrlSafeEncode = (input) => {
  return btoa(input).replace(/\//g, "_").replace(/\+/g, "-").replace(/\=/g, "");
};

const base64UrlSafeDecode = (input) => {
  return atob(input.replace(/_/g, "/").replace(/-/g, "+"));
};

export const base64UrlSafe = (() => {
  return {
    encode: base64UrlSafeEncode,
    decode: base64UrlSafeDecode,
  };
})();
