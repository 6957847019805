import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useRouter } from "next/router";
import { stepLoaded } from "./../Analytics";

/**
 * CloseConfirm
 *
 * Close confirmation screen. Displays if user tries to exit the wizard.
 *
 * @param {*} props
 * @returns
 */
const CloseConfirm = (props) => {
  const { onRequestClose, agent, funnel } = props;
  const screenKey = "ComfimClose";

  const router = useRouter();

  useEffect(() => {
    stepLoaded({ key: screenKey }, router, agent.rea_agent_status, funnel);
  }, []);

  return (
    <div className="MessageAgentModal__close-confirm">
      <div className="MessageAgentModal__close-confirm-inner">
        <svg
          width="90"
          height="90"
          viewBox="0 0 90 90"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="45" cy="45" r="32.5" stroke="#EB5B3C" strokeWidth="5" />
          <line
            x1="45"
            y1="29"
            x2="45"
            y2="53"
            stroke="#EB5B3C"
            strokeWidth="6"
            strokeLinecap="round"
          />
          <circle cx="45" cy="62" r="3" fill="#EB5B3C" />
        </svg>
        <div className="MessageAgentModal__close-confirm-heading">
          Are you sure you want to leave?
        </div>
        <div className="MessageAgentModal__close-confirm-sub-heading">
          You’re almost done. <br /> If you leave now you lose your progress.
        </div>
        <div className="MessageAgentForm__screen-controls --alt">
          <button className="--alt" type="button" onClick={onRequestClose}>
            Yes, discard
          </button>
          <button
            type="button"
            onClick={(e) => {
              onRequestClose(e, null);
            }}
          >
            No, continue request
          </button>
        </div>
      </div>
    </div>
  );
};

CloseConfirm.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
};

CloseConfirm.defaultProps = {
  onRequestClose: () => {},
};

export default CloseConfirm;
