import { capitalize } from "../../../../utils/TextProcessing";
import { calculateBusinessHours } from "~/utils/AgentUtils";
import { StyleSheet } from "aphrodite";
import PropTypes from "prop-types";
import Image from "~/components/Image";
import ReviewRatings from "~/components/Agents/Reviews/ReviewRatings";

/**
 * AgentHeader
 *
 * Agent Header component. Displays if profile uncliamed.
 *
 * @param {*} props
 * @returns
 */
const AgentHeader = (props) => {
  const {
    agent,
    onRequestClose,
    prime,
    useContactForm,
    isPrime,
    isNonPrime,
    city,
    state,
  } = props;

  const styles = StyleSheet.create({
    starSize: {
      fontSize: 16,
    },
  });

  const { times, closed, noTimes } = calculateBusinessHours({
    hours: agent.business_hours,
    timezone: agent.timezone,
  });

  return (
    <div className={`MessageAgentForm__agent-header ${prime ? "--prime" : ""}`}>
      <>
        {!isPrime && !isNonPrime ? (
          <>
            <h2 className={`MessageAgentForm__unclaimed-agent-title`}>
              {`Connect with ${capitalize(
                city
              )}, ${state.toUpperCase()} Agents`}
            </h2>
            <span>
              Message a small number of carefully-vetted agents about your
              buying or selling needs.
            </span>
          </>
        ) : (
          <>
            <Image
              imageSrc={
                agent.photo_url &&
                agent.photo_url.replace(
                  "s3.amazonaws.com/agentmachine_development_pclip",
                  "agentmachine-production.s3.us-west-1.amazonaws.com"
                )
              }
              defaultSrc={"/static/profile_default.webp"}
              alt="agent profile picture"
              containerClassName="MessageAgentForm__agent-profile-picture"
            />
            <div className="MessageAgentForm__agent-information">
              <div className="MessageAgentForm__agent-name">
                {capitalize(agent.display_name)}
              </div>
              <div className="MessageAgentForm__agent-brokerage">
                {capitalize(agent.display_brokerage)}
              </div>
              <div className="MessageAgentForm__agent-rating">
                {agent.review_total !== 0 && (
                  <>
                    <ReviewRatings
                      rating={agent.average_review_rating}
                      readOnly={true}
                      starSize={styles.starSize}
                    />
                    <span className="MessageAgentForm__agent-review-label">
                      {agent.review_total !== null ? agent.review_total : "-"}
                      &nbsp;reviews
                    </span>
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </>
      {prime ? (
        <div className="MessageAgentForm__agent-prime-badge-container">
          <div className="MessageAgentForm__agent-prime-badge">Prime Agent</div>
        </div>
      ) : null}
      {prime ? (
        <div className={"MessageAgentForm__agent-meta-container"}>
          <div className="MessageAgentForm__agent-meta">
            {agent.response_time && !noTimes && (
              <div className="MessageAgentForm__agent-responds">
                Responds in{" "}
                <em>&nbsp;{agent.response_time.replace(/Min/i, "minute")}</em>
              </div>
            )}
            {agent.business_hours &&
              agent.business_hours.length > 0 &&
              !noTimes && (
                <div
                  className={`MessageAgentForm__agent-hours ${
                    closed ? "--closed" : ""
                  }`}
                >
                  {closed ? "Closed" : "Open"} <em>{times}</em>
                </div>
              )}
          </div>
        </div>
      ) : null}
      <button
        className={`MessageAgentForm__close ${prime ? "--alt" : ""}`}
        onClick={(e) => onRequestClose(e, false)}
      >
        Close
      </button>
    </div>
  );
};

AgentHeader.propTypes = {
  agent: PropTypes.object.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  prime: PropTypes.bool.isRequired,
};

AgentHeader.defaultProps = {
  agent: {},
  onRequestClose: () => {},
  prime: false,
};

export default AgentHeader;
