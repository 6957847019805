import { countries as rawCountries } from "countries-list";
import phoneFormats from "~/components/Inputs/data/phoneFormats.js";

const countries = (() => {
  rawCountries[Symbol.iterator] = function () {
    let countries = Object.keys(this);
    let count = 0;
    let isDone = false;

    let next = () => {
      if (count >= countries.length) isDone = true;
      return { done: isDone, value: this[countries[count++]] };
    };

    return { next };
  };

  Object.entries(rawCountries).forEach(([key, value]) => {
    rawCountries[key].countryCode = key;
    if ("phone" in rawCountries[key] && !Array.isArray(rawCountries[key].phone))
      rawCountries[key].phone = rawCountries[key].phone.split(",");
    if (phoneFormats[key.toLowerCase()] != undefined) {
      rawCountries[key].phoneFormat =
        phoneFormats[key.toLowerCase()].phoneFormat;
    } else {
      rawCountries[key].phoneFormat = "... ... ... ..";
    }
  });

  return rawCountries;
})();

const typeAHeadCounties = (input) => {
  input = input.toLowerCase();
  return [...countries].filter((country) => {
    if (
      input != "" &&
      country.name.toLowerCase().slice(0, input.length) == input
    )
      return true;
    return false;
  });
};

export { typeAHeadCounties, rawCountries, countries };
