/**
 * Action helpers
 */

// Router requirements
// import { browserHistory } from 'react-router';

/* global Promise */
import { notifyBugSnag } from "../utils/ErrorChecking";

const helpers = {
  /**
   * parses json
   */
  parseJSON: function parseJSON(response) {
    try {
      return response.json();
    } catch (err) {
      const bugSnagMetadata = [
        {
          label: "response",
          fields: {
            response: response,
            repsonse_status_text: response.statusText,
          },
        },
      ];
      notifyBugSnag({ error: err, metadata: bugSnagMetadata });
      throw err;
    }
  },

  parseText: function parseText(response) {
    return response.text();
  },

  /**
   * checks status of API call
   */
  checkStatus: async function checkStatus(response) {
    try {
      if (response.status >= 200 && response.status < 300) {
        return response;
      }
      if (response.statusText) {
        const bugSnagMetadata = [
          {
            label: "response",
            fields: {
              url: response.url,
              headers: response.headers,
              statusText: response.statusText,
              status: response.status,
            },
          },
        ];
        if (response.status !== 404) {
          notifyBugSnag({
            error: response.statusText,
            metadata: bugSnagMetadata,
          });
        }
      } else {
        const bugSnagMetadata = [
          {
            label: "response",
            fields: {
              url: response.url,
              headers: response.headers,
              statusText: response.statusText,
              status: response.status,
            },
          },
        ];
        if (response.status !== 404) {
          notifyBugSnag({ error: "Fetch Error", metadata: bugSnagMetadata });
        }
      }

      return Promise.reject(response.statusText);
    } catch (err) {
      console.log(err);
      const bugSnagMetadata = [
        {
          label: "response",
          fields: {
            url: response.url,
            headers: response.headers,
            statusText: response.statusText,
            status: response.status,
          },
        },
      ];
      notifyBugSnag({ error: err, metadata: bugSnagMetadata });
      throw err;
    }
  },
  /***
   * Handles the general error cases from an API call.
   * 403 pushes to /login
   * 500 pushes to a bad status page
   */
  handleError: function handleError(error) {
    if (error.response.status === 403) {
      // browserHistory.push('/login');
    }
  },

  errorMsg: function errorMsg(data) {
    if (data.errors) {
      var err = "";
      for (var key in data.errors) {
        if (data.errors.hasOwnProperty(key)) {
          if (err !== "") {
            err += " and ";
          }
          err += data.errors[key];
        }
      }
      console.log(err);
      return err;
    }
  },
};

export default helpers;
