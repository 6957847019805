import PlacesAutocomplete from "react-places-autocomplete";

class CustomPlacesAutocomplete extends PlacesAutocomplete {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      tabIndex: 0,
    };
  }

  clearSuggestions = () => {
    this.setState({ suggestions: [], tabIndex: 0 });
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.userInputValue !== this.state.userInputValue) {
      this.setState({
        tabIndex: 0,
      });
    }
  };

  handleInputKeyDown = (event) => {
    switch (event.key) {
      case "Enter":
        event.preventDefault();
        this.handleEnterKey();
        break;
      case "ArrowDown":
        event.preventDefault(); // prevent the cursor from moving
        this.handleDownKey();
        break;
      case "ArrowUp":
        event.preventDefault(); // prevent the cursor from moving
        this.handleUpKey();
        break;
      case "Escape":
        this.clearSuggestions();
        break;
      case "Tab":
        event.preventDefault();
        if (this.state.tabIndex === 0) {
          this.setState({
            tabIndex: 1,
          });
          this.selectActiveAtIndex(0);
        } else {
          this.handleDownKey();
        }
        // this.handleEnterKey()
        break;
    }
  };
}

export default CustomPlacesAutocomplete;
