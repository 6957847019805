import { capitalize } from "./TextProcessing";
export const calculateBreadcrumbSchema = ({
  pathnameArray,
  pathArray,
  agent,
  city,
  state,
}) => {
  let breadcrumbList = [
    {
      "@type": "ListItem",
      position: 0,
      name: `Home`,
      item: `${process.env.CLIENT_ORIGIN}`,
    },
  ];

  for (let i = 0; i < pathArray.length; i++) {
    let breadcrumb = {
      "@type": "ListItem",
      position: i + 1,
    };
    let breadcrumbAsPath = "";

    if (pathnameArray[i] && pathnameArray[i].includes("[agent_slug]")) {
      breadcrumb.name = `${agent.display_name}; ${agent.display_city}, ${agent.display_state}`;
    } else if (pathnameArray[i] && pathnameArray[i].includes("[location]")) {
      breadcrumb.name = `${city}, ${state}`;
    } else if (pathnameArray[i] === "contact") {
      breadcrumb.name = "Contact Us";
    } else if (pathnameArray[i] === "tos") {
      breadcrumb.name = "Terms of Service";
    } else if (pathnameArray[i] === "privacy") {
      breadcrumb.name = "Privacy Policy";
    } else {
      breadcrumb.name = capitalize(pathArray[i]);
    }

    breadcrumbAsPath = process.env.CLIENT_ORIGIN;

    for (let j = 0; j <= i; j++) {
      breadcrumbAsPath += `/${pathArray[j]}`;
    }

    breadcrumb.item = breadcrumbAsPath;

    breadcrumbList.push(breadcrumb);
  }
  const breadcrumbJson = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: breadcrumbList,
  };
  return breadcrumbJson;
};
