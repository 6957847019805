import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { createLogger } from "redux-logger";
import { createWrapper } from "next-redux-wrapper";
import thunkMiddleware from "redux-thunk";
import reducer from "./index";

export function configureStore(context) {
  const middleware = [thunkMiddleware];

  if (process.env.NODE_ENV === "development") {
    const logger = createLogger({
      colors: false,
    });
    // middleware.push(logger); // Logger must be the last item in middleware
  }

  let store = createStore(
    reducer,
    {},
    composeWithDevTools(applyMiddleware(...middleware))
  );
  return store;
}

// export an assembled wrapper
export const wrapper = createWrapper(configureStore, { debug: false });
