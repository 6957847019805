export const CTA_FUNNEL = "2nd_cta_funnel";
export const CITY_FUNNEL = "city_page_funnel";
export const WORKING_WITH_AN_AGENT_FUNNEL = "working_with_an_agent";
export const HOMEPAGE_FUNNEL = "landing_page_funnel";
export const HOMEPAGE_TILE_FUNNEL = "landing_page_tile_funnel";
export const BLOG_FUNNEL = "blog_page_funnel";
export const HOW_TO_FIND_A_REAL_ESTATE_AGENT =
  "how_to_find_a_real_estate_agent";
export const COMPARING_AGENTS_FUNNEL = "comparing_agents_funnel";
export const HOW_AGENTS_WORK_FUNNEL = "how_agents_work_funnel";
export const REVIEWS_FUNNEL = "reviews_page_funnel";
export const HOW_IT_WORKS_FUNNEL = "how_it_works_funnel";
export const HOMES_FOR_SALE_NEAR_YOU_FUNNEL = "homes_for_sale_near_you_funnel";
export const RENT_OR_BUY_A_HOME_FUNNEL = "rent_or_buy_a_home_funnel";
export const MEGA_MENU_BUYER = "mega_menu_buyer_funnel";
export const MEGA_MENU_SELLER = "mega_menu_seller_funnel";
export const MEGA_MENU_ABOUT = "mega_menu_about_funnel";

export const genericFunnels = [
  CTA_FUNNEL,
  CITY_FUNNEL,
  WORKING_WITH_AN_AGENT_FUNNEL,
  HOMEPAGE_FUNNEL,
  HOMEPAGE_TILE_FUNNEL,
  BLOG_FUNNEL,
  HOW_TO_FIND_A_REAL_ESTATE_AGENT,
  COMPARING_AGENTS_FUNNEL,
  HOW_AGENTS_WORK_FUNNEL,
  REVIEWS_FUNNEL,
  HOW_IT_WORKS_FUNNEL,
  HOMES_FOR_SALE_NEAR_YOU_FUNNEL,
  RENT_OR_BUY_A_HOME_FUNNEL,
  MEGA_MENU_BUYER,
  MEGA_MENU_SELLER,
  MEGA_MENU_ABOUT,
];
