import PropTypes from "prop-types";
import React from "react";

/**
 * ClientTimeFrame
 *
 * Client timeframe screen. Collects users timeframe.
 *
 * @param {*} props
 * @returns
 */
const ClientTimeFrame = (props) => {
  const {
    clientTimeFrame,
    clientType,
    setClientTimeFrame,
    nextScreen,
    prevScreen,
  } = props;
  const screenKey = "ClientTimeFrame";

  //set context dependent text strings
  const label =
    clientType == "selling" || clientType == "buying and selling"
      ? "sell"
      : "buy";

  const handleChange = (e) => {
    setClientTimeFrame(e.target.value);
    setTimeout(() => {
      nextScreen({ key: screenKey, value: e.target.value });
    }, 200);
  };

  return (
    <div className="MessageAgentForm__screen" key="timeframe">
      <div className="MessageAgentForm__screen-heading">
        When do you plan to {label} your home?
      </div>
      <div className="MessageAgentForm__screen-grid">
        <label>
          <input
            name="timeframe"
            type="radio"
            value="less than 1 week"
            onClick={(e) => {
              handleChange(e);
            }}
            checked={clientTimeFrame === "less than 1 week"}
          />
          <div>Immediately</div>
        </label>
        <label>
          <input
            name="timeframe"
            type="radio"
            value="1 month or less"
            onClick={(e) => {
              handleChange(e);
            }}
            checked={clientTimeFrame === "1 month or less"}
          />
          <div>1 month or less</div>
        </label>
        <label>
          <input
            name="timeframe"
            type="radio"
            value="2 - 3 months"
            onClick={(e) => {
              handleChange(e);
            }}
            checked={clientTimeFrame === "2-3 months"}
          />
          <div>2-3 months</div>
        </label>
        <label>
          <input
            name="timeframe"
            type="radio"
            value="3-6 months"
            onClick={(e) => {
              handleChange(e);
            }}
            checked={clientTimeFrame === "3-6 months"}
          />
          <div>3-6 months</div>
        </label>
        <label>
          <input
            name="timeframe"
            type="radio"
            value="6-9 months"
            onClick={(e) => {
              handleChange(e);
            }}
            checked={clientTimeFrame === "6-9 months"}
          />
          <div>6-9 months</div>
        </label>
        <label>
          <input
            name="timeframe"
            type="radio"
            value="9 months or more"
            onChange={(e) => {
              handleChange(e);
            }}
            checked={clientTimeFrame === "9 months or more"}
          />
          <div>9 months or more</div>
        </label>
      </div>
      <div className="MessageAgentForm__screen-controls">
        <button
          className="--alt"
          type="button"
          onClick={() => {
            prevScreen({ key: screenKey });
          }}
        >
          Back
        </button>
      </div>
    </div>
  );
};

ClientTimeFrame.propTypes = {
  clientTimeFrame: PropTypes.string,
  clientType: PropTypes.string,
  setClientTimeFrame: PropTypes.func.isRequired,
  nextScreen: PropTypes.func.isRequired,
  prevScreen: PropTypes.func.isRequired,
};

ClientTimeFrame.defaultProps = {
  clientTimeFrame: undefined,
  clientType: undefined,
  setClientTimeFrame: () => {},
  nextScreen: () => {},
  prevScreen: () => {},
};

export default ClientTimeFrame;
