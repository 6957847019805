import PropTypes from "prop-types";
import React from "react";
import { capitalize } from "../../../../utils/TextProcessing";

/**
 * MissedConnection
 *
 * Missed connection screen. Displayes if profile is unclaimed and user doesn't choose to message other agents.
 *
 * @param {*} props
 * @returns
 */
const MissedConnection = (props) => {
  const { nextScreen, prevScreen, agent } = props;
  const screenKey = "MissedConnection";

  return (
    <div
      className="MessageAgentForm__screen --alt --missed-connection --no-height"
      key="missedConnection"
    >
      <div className="MessageAgentForm__screen-heading --alt --narrow">
        We appreciate your interest in{" "}
        {capitalize(agent.display_name.split(/(\s+)/)[0])}.
      </div>
      <div className="MessageAgentForm__screen-text --alt">
        Please check again soon.
      </div>
      <div className="MessageAgentForm__screen-controls --alt">
        <button
          className="--alt"
          type="button"
          onClick={() => {
            prevScreen({ key: screenKey });
          }}
        >
          Close
        </button>
        <button
          type="button"
          onClick={() => {
            nextScreen({ key: screenKey });
          }}
        >
          Contact other agents after all
        </button>
      </div>
    </div>
  );
};

MissedConnection.propTypes = {
  agent: PropTypes.object.isRequired,
  nextScreen: PropTypes.func.isRequired,
  prevScreen: PropTypes.func.isRequired,
};

MissedConnection.defaultProps = {
  agent: {},
  nextScreen: () => {},
  prevScreen: () => {},
};

export default MissedConnection;
