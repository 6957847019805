import { useState, useEffect } from "react";
const API_KEY = "AIzaSyAhCkJXl-CJIM1m8XplB7mOdi_YMI077e4";

const useGoogleMaps = (apiKey) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Check if the Google Maps API is already loaded
    if (window.google && window.google.maps) {
      setIsLoaded(true);
      return;
    }

    // Create a script tag to load the Google Maps API
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${
      apiKey || API_KEY
    }&v=beta&libraries=places&language=en`;
    script.async = true;
    script.defer = true;

    // Callback when the script is loaded
    script.onload = () => {
      setIsLoaded(true);
    };

    // Error handling
    script.onerror = () => {
      setError("Error loading Google Maps API");
    };

    // Append the script to the document
    document.head.appendChild(script);

    // Cleanup the script when the component is unmounted
    return () => {
      document.head.removeChild(script);
    };
  }, [apiKey]);

  return { isLoaded, error };
};

export default useGoogleMaps;
