import React from "react";
import PropTypes from "prop-types";

/**
 * ClientAlsoSelling
 *
 * Client also selling screen.
 *
 * @param {*} props
 * @returns
 */
const ClientAlsoSelling = (props) => {
  const {
    clientAlsoSelling,
    setClientAlsoSelling,
    nextScreen,
    prevScreen,
  } = props;
  const screenKey = "ClientAlsoSelling";

  const handleChange = (e) => {
    setClientAlsoSelling(e.target.value);
    setTimeout(() => {
      nextScreen({ key: screenKey });
    }, 200);
  };

  return (
    <div className="MessageAgentForm__screen" key="contact-info">
      <div className="MessageAgentForm__screen-heading">
        Are you also looking to sell a home?
      </div>
      <div className="MessageAgentForm__screen-grid --alt">
        <label>
          <input
            name="alsoSelling"
            type="radio"
            value="yes"
            onClick={(e) => {
              handleChange(e);
            }}
            defaultChecked={clientAlsoSelling === "yes"}
          />
          <div>Yes</div>
        </label>
        <label>
          <input
            name="alsoSelling"
            type="radio"
            value="no"
            onClick={(e) => {
              handleChange(e);
            }}
            defaultChecked={clientAlsoSelling === "no"}
          />
          <div>No</div>
        </label>
      </div>
      <div className="MessageAgentForm__screen-controls">
        <button
          className="--alt"
          type="button"
          onClick={() => {
            prevScreen({ key: screenKey });
          }}
        >
          Back
        </button>
      </div>
    </div>
  );
};

ClientAlsoSelling.propTypes = {
  clientAlsoSelling: PropTypes.string,
  setClientAlsoSelling: PropTypes.func.isRequired,
  nextScreen: PropTypes.func.isRequired,
  prevScreen: PropTypes.func.isRequired,
};

ClientAlsoSelling.defaultProps = {
  clientAlsoSelling: undefined,
  setClientAlsoSelling: () => {},
  nextScreen: () => {},
  prevScreen: () => {},
};

export default ClientAlsoSelling;
