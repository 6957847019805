/***
 * Action / Reducer file for modals
 */

/**********************************
 *        ACTIONS SECTION         *
 **********************************/

export const ModalConstants = {
  TOGGLE_READY_TO_SELL_MODAL: "@@modal/TOGGLE_READY_TO_SELL_MODAL",
  TOGGLE_CONTACT_AGENT_MODAL: "@@modal/TOGGLE_CONTACT_AGENT_MODAL",
  TOGGLE_CLAIM_PROFILE_MODAL: "@@modal/TOGGLE_CLAIM_PROFILE_MODAL",
  TOGGLE_REVIEW_MODAL: "@@modal/TOGGLE_REVIEW_MODAL",
};

export const ModalActions = {
  /***
   * toggle ready to sell modal
   * @param {boolean} openModal -- if modal should be open or not
   */
  openReadyToSellModal: (openModal) => {
    return (dispatch, getStore) => {
      return dispatch({
        type: ModalConstants.TOGGLE_READY_TO_SELL_MODAL,
        openReadyToSellModal: openModal,
      });
    };
  },

  /***
   * toggle review modal
   * @param {boolean} openModal -- if modal should be open or not
   */
  openReviewModal: (openModal) => {
    return (dispatch) => {
      return dispatch({
        type: ModalConstants.TOGGLE_REVIEW_MODAL,
        openReviewModal: openModal,
      });
    };
  },

  /***
   * toggle contact agent modal
   * @param {boolean} openModal -- if modal should be open or not
   */
  openContactAgentModal: ({ openModal, modalOptions }) => {
    return (dispatch) => {
      return dispatch({
        type: ModalConstants.TOGGLE_CONTACT_AGENT_MODAL,
        openContactAgentModal: openModal,
        contactAgentOptions: modalOptions,
      });
    };
  },

  /***
   * toggle ready to sell modal
   * @param {boolean} openModal -- if modal should be open or not
   */
  openClaimProfileModal: (openModal) => {
    return (dispatch) => {
      return dispatch({
        type: ModalConstants.TOGGLE_CLAIM_PROFILE_MODAL,
        openClaimProfileModal: openModal,
      });
    };
  },
};

/**********************************
 *        REDUCER SECTION         *
 **********************************/

const defaultModalState = {
  openReadyToSellModal: false,
  openContactAgentModal: false,
  openClaimProfileModal: false,
  openReviewModal: false,
  contactAgentOptions: {
    agent: null,
  },
};

const ModalReducer = (state = defaultModalState, action) => {
  switch (action.type) {
    case ModalConstants.TOGGLE_READY_TO_SELL_MODAL:
    case ModalConstants.TOGGLE_CONTACT_AGENT_MODAL:
    case ModalConstants.TOGGLE_CLAIM_PROFILE_MODAL:
    case ModalConstants.TOGGLE_REVIEW_MODAL:
      return {
        ...state,
        ...action,
      };
    default:
      return state;
  }
};

export default ModalReducer;
