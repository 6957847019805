/***
 * Action / Reducer server side rendering
 */

/**********************************
 *        ACTIONS SECTION         *
 **********************************/

export const ServerSideRenderConstants = {
  SET_IS_SERVER_SIDE_RENDER: "@@serverSideRender/SET_IS_SERVER_SIDE_RENDER",
};

export const ServerSideRenderActions = {
  /***
   * set if server side rendering or not
   * @isServerSideRender {boolean} -- if currently server side rendering or not
   */
  setIsServerSideRender: ({ isServerSideRender }) => {
    return (dispatch) => {
      return dispatch({
        type: ServerSideRenderConstants.SET_IS_SERVER_SIDE_RENDER,
        isServerSideRender: isServerSideRender,
      });
    };
  },
};

/**********************************
 *        REDUCER SECTION         *
 **********************************/

const defaultServerSideRenderState = {
  isServerSideRender: false,
};

const ServerSideRenderReducer = (
  state = defaultServerSideRenderState,
  action
) => {
  switch (action.type) {
    case ServerSideRenderConstants.SET_IS_SERVER_SIDE_RENDER:
      return {
        ...state,
        ...action,
      };
    default:
      return state;
  }
};

export default ServerSideRenderReducer;
