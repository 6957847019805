import styles from "./Toggle.module.scss";
import { useState } from "react";
import PropTypes from "prop-types";

const Toggle = (props) => {
  const {
    label,
    name,
    checked,
    className,
    onChange,
    onFocus,
    value,
    required,
    children,
  } = props;
  const [checkedState, setCheckedState] = useState(checked);

  const classNames = [styles["Toggle"], className].join(" ").trim();

  const handleChange = (e) => {
    const state = !checkedState;
    setCheckedState(state);
    onChange(e, state);
  };

  const handleFocus = (e) => {
    onFocus(e);
  };

  return (
    <div className={classNames}>
      <label>
        <div className={styles["Toggle__container"]}>
          <input
            name={name}
            type="checkbox"
            defaultChecked={checkedState}
            onChange={handleChange}
            onFocus={handleFocus}
            value={value}
            required={required}
          />
          <span className={styles["Toggle__slider"]}></span>
        </div>
        {children || label}
      </label>
    </div>
  );
};

Toggle.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  name: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onFocus: PropTypes.func,
};

Toggle.defaultProps = {
  checked: false,
  className: "",
  label: "",
  name: null,
  onChange: () => {},
  required: null,
  value: "",
  onFocus: () => {},
};

export default Toggle;
