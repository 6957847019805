import Link from "next/link";
import MegaMenu from "@referralexchange/rea-ui-react/components/navigation/MegaMenu";
import HeadingWithLinks from "@referralexchange/rea-ui-react/components/navigation/HeadingWithLinks";
import Content from "@referralexchange/rea-ui-react/atoms/Content";

import HouseWithPriceTagIcon from "@referralexchange/rea-ui-react/svgs/nav/house-with-price-tag";
import HouseWithHeartIcon from "@referralexchange/rea-ui-react/svgs/house-with-heart";
import BookOpenIcon from "@referralexchange/rea-ui-react/svgs/book-open";
import BubbleWithQuestionMarkIcon from "@referralexchange/rea-ui-react/svgs/bubble-with-question-mark";
import MagnifyingGlassIcon from "@referralexchange/rea-ui-react/svgs/magnifying-glass";

import CtaButton from "~/components/CtaButton";
import { MEGA_MENU_BUYER } from "~/config/funnel";

export default function BuyerMegaMenu() {
  const leftCTA = (
    <div className={MegaMenu.styles.cta}>
      <HouseWithPriceTagIcon title="Buying a Home Icon" />
      <Content className={Content.styles.medium}>Buying a Home?</Content>
      <Content
        className={[Content.styles.body14, Content.styles.center].join(" ")}
      >
        Find your perfect local real estate agent
      </Content>
      <CtaButton funnel={MEGA_MENU_BUYER} type="buying">
        Explore Agents
      </CtaButton>
    </div>
  );

  const centerItems = [
    <Link href="/working-with-an-agent">Working with a Real Estate Agent</Link>,
    <Link href="/how-to-find-a-real-estate-agent">
      How to Find a Real Estate Agent
    </Link>,
    <Link href="/how-to-compare-real-estate-agents">
      How to Compare Real Estate Agents
    </Link>,
    <Link href="/how-a-real-estate-agent-works-for-you">
      How a Real Estate Agent Works for You
    </Link>,
  ];

  const rightItems = [
    <Link href="/buy">
      <span
        className={[
          HeadingWithLinks.styles.icon,
          HeadingWithLinks.styles.iconFill,
        ].join(" ")}
      >
        <HouseWithHeartIcon />
      </span>
      Find a Buyer&apos;s Agent
    </Link>,
    <Link href="/blog/buying">
      <span className={HeadingWithLinks.styles.icon}>
        <BookOpenIcon />
      </span>
      Buyer&apos;s Blog
    </Link>,
    <Link href="/faq/for-buyers">
      <span className={HeadingWithLinks.styles.icon}>
        <BubbleWithQuestionMarkIcon />
      </span>
      Buying a Home FAQ
    </Link>,
    <Link href="/search">
      <span className={HeadingWithLinks.styles.icon}>
        <MagnifyingGlassIcon />
      </span>
      Search by Location
    </Link>,
  ];

  return (
    <MegaMenu
      label="Buyers"
      left={leftCTA}
      center={
        <HeadingWithLinks heading={"Buyer's Guides"} items={centerItems} />
      }
      right={
        <HeadingWithLinks heading={"Buyer's Resources"} items={rightItems} />
      }
    />
  );
}
