import { useRouter } from "next/router";
import { useContext, createContext, useState, useEffect } from "react";
import { useToasts } from "react-toast-notifications";
import { createFunnelEvent } from "~/utils/Fetch/Analytics";

const AgentSearchContext = createContext();

export const useAgentSearch = () => useContext(AgentSearchContext);
const MAX_AGENTS = 4;

export const AgentSearchContextProvider = ({ children }) => {
  const [selectedAgents, setSelectedAgents] = useState({});
  const [selectedOtherAgents, setSelectedOtherAgents] = useState({});
  const [selectedAgentIndices, setSelectedAgentIndices] = useState({});
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [country, setCountry] = useState("");
  const [streetName, setStreetName] = useState("");
  const [streetNumber, setStreetNumber] = useState("");
  const [leadTypeInner, setLeadTypeInner] = useState("seller");
  const [lat, setLat] = useState("");
  const [lon, setLon] = useState("");
  const [agents, setAgents] = useState(Array(4).fill(1));
  const [agentCount, setAgentCount] = useState(0);
  const [minPrice, setMinPrice] = useState(300000);
  const [maxPrice, setMaxPrice] = useState(350000);
  const { addToast } = useToasts();
  const router = useRouter();

  const getQueryParams = () => {
    const params = Object.fromEntries(new URLSearchParams(location.search));
    return params;
  };

  useEffect(() => {
    if (lat && lon) {
      router.push({
        pathname: router.pathname,
        query: { ...router.query, lat: lat, lon: lon },
      });
    }
  }, [lat, lon]);

  const resetData = () => {
    setSelectedAgents({});
    setSelectedOtherAgents({});
    // setCity("");
    // setState("");
    // setZip("");
    // setCountry("");
    // setStreetName("");
    // setStreetNumber("");
    // setLeadTypeInner("");
    // setLat("");
    // setLon("");
  };

  useEffect(() => {
    setLat(router.query.lat);
    setLon(router.query.lon);
  }, [router.query.lat, router.query.lon]);

  useEffect(() => {
    if (router.asPath === "/top-agents") {
      resetData();
    }
  }, [router]);

  useEffect(() => {
    if (zip) {
      window.localStorage.setItem("twopage-zip", zip);
    }
  }, [zip]);

  useEffect(() => {
    if (country) {
      window.localStorage.setItem("twopage-country", country);
    }
  }, [country]);

  useEffect(() => {
    if (streetNumber) {
      window.localStorage.setItem("twopage-streetNumber", streetNumber);
    }
  }, [streetNumber]);

  useEffect(() => {
    if (leadTypeInner) {
      window.localStorage.setItem("twopage-leadType", leadTypeInner);
    }
  }, [leadTypeInner]);

  useEffect(() => {
    if (streetName) {
      window.localStorage.setItem("twopage-streetName", streetName);
    }
  }, [streetName]);

  useEffect(() => {
    if (city) {
      window.localStorage.setItem("twopage-city", city);
    }
  }, [city]);

  useEffect(() => {
    if (state) {
      window.localStorage.setItem("twopage-state", state);
    }
  }, [state]);

  useEffect(() => {
    const max_price = router.query.max_price;
    const min_price = router.query.min_price;

    if (max_price) {
      const intPrice = parseInt(max_price, 10);
      setMaxPrice(intPrice);
    } else {
      const priceMax = window.localStorage.getItem("maxPrice");
      if (priceMax) {
        setMaxPrice(parseInt(priceMax, 10));
      }
    }

    if (min_price) {
      const intPrice = parseInt(min_price, 10);
      setMinPrice(intPrice);
    } else {
      const priceMin = window.localStorage.getItem("maxPrice");
      if (priceMin) {
        setMinPrice(parseInt(priceMin, 10));
      }
    }
  }, []);

  useEffect(() => {
    const storedLat =
      window.localStorage.getItem("twopage-lat") || router.query.lat;
    const storedLon =
      window.localStorage.getItem("twopage-lon") || router.query.lon;

    const storedCity =
      window.localStorage.getItem("twopage-city") || router.query.city;
    const storedState =
      window.localStorage.getItem("twopage-state") || router.query.state;

    const storedZip = window.localStorage.getItem("twopage-zip");
    const storedCountry = window.localStorage.getItem("twopage-country");
    const storedStreetNumber = window.localStorage.getItem(
      "twopage-streetNumber"
    );
    const storedStreetName = window.localStorage.getItem("twopage-streetName");

    if (storedStreetNumber) {
      setStreetNumber(storedStreetNumber);
    }

    if (storedStreetName) {
      setStreetName(storedStreetName);
    }

    if (storedCountry) {
      setCountry(storedCountry);
    }

    if (storedZip) {
      setZip(storedZip);
    }

    if (storedCity) {
      setCity(storedCity);
    }

    if (storedState) {
      setState(storedState);
    }

    if (storedLat) {
      setLat(storedLat);
    }

    if (storedLon) {
      setLon(storedLon);
    }

    const storedLeadType =
      router.query.lead_type || window.localStorage.getItem("twopage-leadType");

    if (storedLeadType) {
      setLeadTypeInner(storedLeadType);
    }
  }, []);

  const selectAgent = ({ agent, index, manual }) => {
    const agents = { ...selectedAgents };
    const numSelectedAgents = Object.keys(selectedAgents).length;
    const indices = { ...selectedAgentIndices };

    if (numSelectedAgents === MAX_AGENTS) {
      addToast("4 agents have already been selected!", {
        appearance: "warning",
        autoDismiss: true,
        placement: "top-center",
      });

      return;
    }

    createFunnelEvent({
      step: "show-agents",
      funnel_name: "agent-search",
      eventType: "agent-selected",
      page: router.asPath.split("?")[0],
      details: {
        agent_id: agent.id,
        agent_name: agent.display_name,
        brokerage: agent.display_brokerage,
        position: index,
        manual,
        agent_indices_selected: Object.keys(selectedAgentIndices).filter(
          (key) => selectedAgentIndices[key]
        ),
      },
    });

    agent.selected_index = index;

    agents[agent.id] = agent;
    indices[index] = true;

    setSelectedAgentIndices(indices);

    setSelectedAgents(agents);
  };

  const multiAgentSelect = (agentsSelected) => {
    const agents = {};

    agentsSelected.forEach((agent) => {
      agents[agent.id] = agent;
    });

    setSelectedAgents(agents);

    return agents;
  };

  const removeAgent = ({ agent, index }) => {
    const agents = { ...selectedAgents };
    delete agents[agent.id];
    setSelectedAgents(agents);
    const indices = { ...selectedAgentIndices };
    indices[index] = false;
    delete indices[index];
    setSelectedAgentIndices(indices);

    createFunnelEvent({
      step: "show-agents",
      funnel_name: "agent-search",
      eventType: "agent-removed",
      page: router.asPath.split("?")[0],
      details: {
        agent_id: agent.id,
        agent_name: agent.display_name,
        brokerage: agent.display_brokerage,
        position: index,
        agent_indices_selected: Object.keys(indices).filter(
          (key) => indices[key]
        ),
      },
    });
  };

  const roundToNearestTenThousand = (num) => {
    return Math.round(num / 10000) * 10000;
  };

  const calculatePriceRange = (agentPricePoints) => {
    return agentPricePoints?.price_log10_seller_mu
      ? [
          roundToNearestTenThousand(
            10 **
              (agentPricePoints.price_log10_seller_mu -
                0.85 * agentPricePoints.price_log10_seller_sd)
          ),
          roundToNearestTenThousand(
            10 **
              (agentPricePoints.price_log10_seller_mu +
                1.5 * agentPricePoints.price_log10_seller_sd)
          ),
        ]
      : null;
  };

  return (
    <AgentSearchContext.Provider
      value={{
        selectedAgents,
        calculatePriceRange,
        setSelectedAgents,
        selectAgent,
        removeAgent,
        multiAgentSelect,
        city,
        setCity,
        state,
        setState,
        zip,
        setZip,
        country,
        setCountry,
        streetName,
        setStreetName,
        streetNumber,
        setStreetNumber,
        leadTypeInner,
        setLeadTypeInner,
        lat,
        lon,
        setLat,
        setLon,
        getQueryParams,
        selectedAgentIndices,
        setSelectedAgentIndices,
        agents,
        setAgents,
        resetData,
        setSelectedOtherAgents,
        selectedOtherAgents,
        setAgentCount,
        agentCount,
        minPrice,
        setMinPrice,
        maxPrice,
        setMaxPrice,
      }}
    >
      {children}
    </AgentSearchContext.Provider>
  );
};

export default AgentSearchContext;
