import MessageAgentForm from "~/components/Agents/MessageAgent/MessageAgentForm";
import MessageAgentModal from "~/components/Agents/MessageAgent/MessageAgentModal";
import { useEffect, useState } from "react";
import {
  postEvent,
  resetSession,
} from "~/components/Agents/MessageAgent/Analytics";
import { trackVirtualPageView } from "~/utils/Fetch/Analytics";
import { useRouter } from "next/router";
import Button from "@referralexchange/rea-ui-react/atoms/Button";

export default function CtaButton({
  funnel,
  type = null,
  children,
  ...childProps
}) {
  const INITIAL_STEP = 1;
  const router = useRouter();
  const [confirmClose, setConfirmClose] = useState(false);
  const [step, setStep] = useState(INITIAL_STEP);
  const [largeModal, setLargeModal] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (modalOpen) {
      setStep(INITIAL_STEP);
    }
  }, [modalOpen]);

  const onRequestClose = (e, confirm = false) => {
    if (step === INITIAL_STEP) {
      setConfirmClose(false);
      postEvent("message_agent_modal_closed", router, null, funnel);
      resetSession();
      setModalOpen(false);
    } else if (confirm) {
      setConfirmClose(true);
      postEvent("message_agent_modal_exit_attempt", router, null, funnel);
    } else if (confirm === null) {
      setConfirmClose(false);
    } else {
      setModalOpen(false);
      postEvent("message_agent_modal_closed", router, null, funnel);
      resetSession();
      setTimeout(() => {
        setConfirmClose(false);
      }, 200);
    }
  };

  return (
    <>
      <Button
        onClick={() => {
          setModalOpen(true);
          trackVirtualPageView("funnel_start", funnel);
        }}
        {...childProps}
      >
        {children}
      </Button>
      <MessageAgentModal
        confirmClose={confirmClose}
        isOpen={modalOpen}
        largeModal={largeModal}
        onRequestClose={onRequestClose}
        agent={{}}
        funnel={funnel}
      >
        <MessageAgentForm
          agent={{ prime: true }}
          funnel={funnel}
          isClaimed={true}
          isOpen={modalOpen}
          afterNewScreen={setStep}
          step={step}
          onRequestClose={onRequestClose}
          onRequestLargeModal={setLargeModal}
          selectedClientType={type}
        />
      </MessageAgentModal>
    </>
  );
}
