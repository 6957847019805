import API, { generateApiUrl } from "~/config/api";
import Helpers from "~/config/helpers";
import { parseCookies, setCookie } from "nookies";
import queryString from "query-string";
import { getAdData } from "./Funnel";

export const queryParams = () => {
  return (
    window.localStorage.getItem("loadedQueryParams") || window.location.search
  );
};

const qpf = queryParams;

const WHITELISTED_AD_PARAMETERS = {
  type_id: true,
  origin: true,
  funnel: true,
  github_revision: true,
  referrer: true,
  gclid: true,
  msclkid: true,
  fbclid: true,
  origin_path: true,
};

export function getAdDataFromParams(params) {
  const paramKeys = Object.keys(params);
  const savedAdData = {
    utm_host: "realestateagents",
  };

  for (let i = 0; i < paramKeys.length; i++) {
    const curKey = paramKeys[i];
    if (curKey.includes("utm_") || WHITELISTED_AD_PARAMETERS[curKey]) {
      savedAdData[curKey] = params[curKey];
    }
  }

  return savedAdData;
}

const getTwoPageFunnelSessionData = (sessionData) => {
  return {
    funnel_name: "two-page-funnel",
    query_string: window.location.search,
    query_params: Object.fromEntries(new URLSearchParams(location.search)),
    lead_type: window.localStorage.getItem("twopage-leadType") || "seller",
    ...sessionData,
  };
};

export function createFunnelEvent({
  details,
  step,
  eventType,
  page,
  funnel_name = "two-page-funnel",
}) {
  const data = {
    funnel_name,
    page,
    details,
    session_uuid: window.localStorage.getItem("rea_session_uuid"),
    event_type: eventType,
    step,
  };
  return fetch(
    generateApiUrl("insights/funnel_event"),
    API.POST_CONFIG({
      data,
    })
  );
}

/***
 * This creates a request in the table analytics.funnel_requests, separate from analytics.rea_funnel_requests
 */
export function createFunnelRequest({
  page,
  request_type,
  sessionUUID,
  leadUUID,
  expid,
  server,
  params,
  userAgent,
  responseCode,
  sessionData,
}) {
  let adDataJson = {};

  adDataJson = getAdData();

  const session_uuid =
    sessionUUID !== undefined
      ? sessionUUID
      : window.localStorage.getItem("rea_session_uuid");
  const lead_uuid =
    leadUUID !== undefined
      ? leadUUID
      : window.localStorage.getItem("lead_uuid");
  const data = {
    session_uuid,
    lead_uuid,
    response_code: responseCode,
    page,
    user_agent: userAgent,
    request_type,
    ad_data: adDataJson,
    session_data: getTwoPageFunnelSessionData(sessionData),
  };

  if (request_type === "SERVER_GET" || responseCode === 404) {
    data.page_load_completed = false;
  }

  return fetch(
    generateApiUrl("insights/funnel_request"),
    API.POST_CONFIG({
      data,
      server,
      sessionUUID: session_uuid,
      leadUUID: lead_uuid,
    })
  );
}

export const reaFunnelRequests = (params) => {
  if (process.browser) {
    let cookies = parseCookies();

    const qs = qpf();

    let search = queryString.parse(qs);
    let queryParams = {};

    if (cookies.utm_params) {
      try {
        queryParams = JSON.parse(cookies.utm_params);
      } catch (e) {
        queryParams = {};
      }
    } else {
      queryParams = {};
    }

    queryParams = {
      ...queryParams,
      ...search,
    };

    setCookie(null, "utm_params", JSON.stringify(queryParams), {
      path: "/",
    });

    params.ad_data = {
      ...queryParams,
    };
  }
  return fetch(API.reaFunnelRequests({}), API.POST_CONFIG({ data: params }))
    .then(Helpers.checkStatus)
    .then(Helpers.parseJSON)
    .then((resp) => {
      return resp;
    });
};

/**
 * Post analytics of action tracking
 * @param { String } action -- action being recorded
 * @param { Object } action_details -- extra action information being passed
 * @param { String } lead_uuid -- tar lead id from email
 * @param { String } session_uuid -- session uuid stored in localstorage
 * @param { String } query_parameters -- query parameters
 */
export const postAnalytics = ({
  action,
  action_details,
  lead_uuid,
  session_uuid,
  query_parameters,
  path,
}) => {
  return fetch(
    API.postAnalytics(),
    API.POST_CONFIG({
      data: {
        action,
        action_details,
        lead_uuid,
        session_uuid,
        query_parameters,
        path,
      },
    })
  ).then(Helpers.checkStatus);
};

function getPathFromUrl(url) {
  return url.split(/[?#]/)[0];
}

/**
 * Post analytics of action tracking
 * @param { String } lead_uuid -- tar lead id from email
 * @param { String } parameters -- query parameters
 * @param { String } page -- which page we're on
 */
export const trackPageView = ({
  lead_uuid,
  session_uuid,
  parameters,
  page,
  pageVersion,
  pageType,
  statusCode = 200,
  ctx,
}) => {
  const data = {
    lead_uuid,
    session_uuid,
    parameters,
    page_version: pageVersion,
    page_type: pageType,
    page: getPathFromUrl(page),
    statusCode,
  };

  if (ctx) {
    data.user_agent = ctx.req?.headers["user-agent"];
  }

  return fetch(
    API.PAGE_VIEW({ ctx }),
    API.POST_CONFIG({
      data,
      ctx,
    })
  ).then(Helpers.checkStatus);
};

export const exportPageType = (path) => {
  if (path.includes("/[state_slug]/[city_slug]/[agent_slug]")) {
    return "agent";
  } else if (path.includes("/[state_slug]/[city_slug]")) {
    return "city";
  } else if (path === "/") {
    return "homepage";
  } else {
    return "info";
  }
};

export const exportPageVersion = (path, agentVersion) => {
  if (path.includes("/[state_slug]/[city_slug]/[agent_slug]")) {
    return agentVersion;
  } else if (path.includes("/[state_slug]/[city_slug]")) {
    return "redesign";
  } else if (path === "/") {
    return "redesign";
  } else {
    return "redesign";
  }
};

export function trackVirtualPageView(funnelEvent, funnel, clientType = null) {
  try {
    let path = `${window.location.pathname}/${funnelEvent}`;
    if (window.location.pathname === "/") {
      path = `/${funnelEvent}`;
    }
    let leadType = "other";
    switch (clientType) {
      case "buying":
        leadType = "buyer";
        break;
      case "selling":
        leadType = "seller";
        break;
      case "buying and selling":
        leadType = "both";
        break;
      default:
        leadType = "other";
        break;
    }
    let funnelEventData = {
      event: "virtualPageView",
      eventValue: path,
      virtualPage: clientType ? `/success/${leadType}` : `/${funnelEvent}`,
      funnelName: funnel,
    };
    if (clientType) {
      funnelEventData.leadType = leadType;
    }
    dataLayer.push(funnelEventData);
  } catch (err) {
    console.log(err);
    console.error("Unable to track Virtual Page View.");
  }
}
