import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faSearch,
  faTimes,
  faPlus,
  faPhoneAlt,
  faHeart,
  faLongArrowRight,
  faLongArrowLeft,
  faBars,
  faClipboardList,
  faCheck,
  faQuestionCircle,
  faTrophyAlt as regTrophyAlt,
  faSlash,
  faHandHoldingUsd,
  faHandsHeart,
  faHomeHeart as faHomeHeartRegular,
  faChartLine as faChartLineRegular,
  faSign as faSignRegular,
  faLockAlt,
  faUserLock,
  faHouseLeave,
  faSackDollar as farSackDollar,
  faRocket,
  faStar as farStar,
  faAngleRight,
  faHouse,
  faBuilding as faBuildingRegular,
  faHotel,
  faCaravan,
  faMapMarked,
  faEnvelope as farEnvelope,
  faSpinnerThird,
  faBadgeCheck,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faSearch as fasSearch,
  faStar,
  faAward,
  faMedal,
  faTrophyAlt,
  faSackDollar,
  faRabbitFast,
  faMale,
  faHeart as solidHeart,
  faPhoneAlt as solidPhoneAlt,
  faEnvelope,
  faMapMarkerAlt,
  faUser,
  faFileAlt,
  faInfoCircle,
  faAddressCard,
  faCheckCircle,
  faChevronDown,
  faChevronUp,
  faCircle,
  faCaretRight,
  faCaretLeft,
  faGraduationCap,
  faBriefcase,
  faArrowsCross,
  faScribble,
  faCaretDown,
  faCaretUp,
  faLocationDot,
} from "@fortawesome/pro-solid-svg-icons";
import {
  faTimesCircle,
  faExclamationCircle,
  faChevronLeft,
  faNewspaper,
  faHomeHeart,
  faSign,
  faReceipt,
  faBuilding,
  faStopwatch,
  faSlash as faSlashLight,
  faHandHoldingUsd as faHandHoldingUsdLight,
  faTrophyAlt as faTrophyAltLight,
  faMapMarkerAlt as faMapMarkerAltLight,
  faTimes as faTimesLight,
  faSearch as faSearchLight,
} from "@fortawesome/pro-light-svg-icons";
import { faFacebookF, faInstagram } from "@fortawesome/free-brands-svg-icons";

import {
  faSpinnerThird as fadSpinnerThird,
  faCirclePhone,
} from "@fortawesome/pro-duotone-svg-icons";

library.add(
  faCirclePhone,
  faSearchLight,
  faMapMarked,
  faCaravan,
  faHotel,
  faBuildingRegular,
  faHouse,
  faHouseLeave,
  faUserLock,
  faLockAlt,
  faSignRegular,
  faChartLineRegular,
  faHomeHeartRegular,
  faTrophyAltLight,
  faSlashLight,
  faHandHoldingUsdLight,
  faStopwatch,
  faCircle,
  faChevronDown,
  faSearch,
  fasSearch,
  faTimes,
  faPhoneAlt,
  faStar,
  faTimesCircle,
  faAward,
  faMedal,
  faTrophyAlt,
  faSackDollar,
  faRabbitFast,
  faMale,
  faHeart,
  solidHeart,
  faEnvelope,
  solidPhoneAlt,
  faMapMarkerAlt,
  faLongArrowRight,
  faLongArrowLeft,
  faUser,
  faScribble,
  faBadgeCheck,
  faBars,
  faFileAlt,
  faInfoCircle,
  faAddressCard,
  faCheckCircle,
  faClipboardList,
  faExclamationCircle,
  faChevronLeft,
  faNewspaper,
  faCheck,
  faQuestionCircle,
  faHomeHeart,
  faSign,
  faBuilding,
  faReceipt,
  regTrophyAlt,
  faSlash,
  faHandHoldingUsd,
  faHandsHeart,
  faMapMarkerAltLight,
  farSackDollar,
  faRocket,
  farStar,
  faAngleRight,
  farEnvelope,
  faSpinnerThird,
  fadSpinnerThird,
  faChevronUp,
  faCaretRight,
  faCaretLeft,
  faGraduationCap,
  faTimesLight,
  faBriefcase,
  faFacebookF,
  faInstagram,
  faCaretDown,
  faCaretUp,
  faPlus,
  faLocationDot
);
