import PropTypes from "prop-types";
import React from "react";

/**
 * AgentUnavailable
 *
 * Agent unavailable screen. Displayes if profile is unclaimed.
 *
 * @param {*} props
 * @returns
 */
const AgentUnavailable = (props) => {
  const { nextScreen, prevScreen } = props;
  const screenKey = "AgentUnavailable";

  return (
    <div
      className="MessageAgentForm__screen --alt --no-height"
      key="agentUnavailable"
    >
      <div className="MessageAgentForm__screen-text --alt">
        Unfortunately, messaging is not currently activated for this agent.
      </div>
      <div className="MessageAgentForm__screen-heading --alt">
        Would you like to message other agents?
      </div>
      <div className="MessageAgentForm__screen-controls --alt">
        <button
          className="--alt"
          type="button"
          onClick={() => {
            prevScreen({ key: screenKey });
          }}
        >
          No
        </button>
        <button
          type="button"
          onClick={() => {
            nextScreen({ key: screenKey });
          }}
        >
          Yes, message other agents
        </button>
      </div>
      {/* Might be used on city page */}
      {/* <ul className="MessageAgentForm__screen-list">
        <li>See your personalized list of agents in less than 2 mins.</li>
        <li>
          Over <strong>26,700</strong> people helped in the past 30 days.
        </li>
        <li>100% free service. No strings attached.</li>
      </ul> */}
    </div>
  );
};

AgentUnavailable.propTypes = {
  nextScreen: PropTypes.func.isRequired,
  prevScreen: PropTypes.func.isRequired,
};

AgentUnavailable.defaultProps = {
  nextScreen: () => {},
  prevScreen: () => {},
};

export default AgentUnavailable;
