import ProgressBar from "~/components/ProgressBar";
import PropTypes from "prop-types";
import React from "react";
import AgentHeader from "./AgentHeader";
import { genericFunnels } from "~/config/funnel";

/**
 * ProgressHeader
 *
 * Progress bar header. Will display AgentHeader if profile
 * is claimed.
 * @param {*} props
 * @returns
 */
const ProgressHeader = (props) => {
  const {
    agent,
    claimed,
    currentStep,
    maxSteps,
    onRequestClose,
    prime,
    funnel,
    useContactForm,
    isPrime,
    isNonPrime,
  } = props;

  return (
    <div className="MessageAgentForm__progress-header">
      {(claimed || prime) && !genericFunnels.includes(funnel) ? (
        <AgentHeader
          agent={agent}
          prime={prime}
          useContactForm={useContactForm}
          onRequestClose={(e) => {
            onRequestClose(e, useContactForm ? false : true);
          }}
          isPrime={isPrime}
          isNonPrime={isNonPrime}
        />
      ) : (
        <button
          className={`MessageAgentForm__close ${
            !genericFunnels.includes(funnel) && prime ? "--alt" : ""
          }`}
          onClick={(e) => {
            onRequestClose(e, useContactForm ? false : true);
          }}
        >
          Close
        </button>
      )}
      {!useContactForm ? (
        <ProgressBar maxSteps={maxSteps} currentStep={currentStep} />
      ) : null}
    </div>
  );
};

ProgressHeader.propTypes = {
  agent: PropTypes.object.isRequired,
  claimed: PropTypes.bool.isRequired,
  currentStep: PropTypes.number.isRequired,
  maxSteps: PropTypes.number.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  prime: PropTypes.bool.isRequired,
};

ProgressHeader.defaultProps = {
  claimed: false,
  currentStep: 0,
  maxSteps: 100,
  agent: {},
  onRequestClose: () => {},
  prime: false,
};

export default ProgressHeader;
