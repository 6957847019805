import { US_STATE_HASH_SHORT_TO_LONG } from "./Constants";
import { capitalize } from "./TextProcessing";
import numeral from "numeral";

export const parseLocation = ({ state, city, zip, neighborhood }) => {
  let location = "";
  let fullLocation = [];
  if (state) {
    location = state.toLocaleUpperCase();
  }
  if (city) {
    location = capitalize(city.toLowerCase());
  }
  if (zip) {
    location = zip;
  }
  if (neighborhood) {
    location = neighborhood;
  }

  if (neighborhood) {
    fullLocation.push(neighborhood);
  }
  if (city) {
    fullLocation.push(capitalize(city.toLowerCase()));
  }
  if (state) {
    fullLocation.push(state.toLocaleUpperCase());
  }
  if (zip) {
    fullLocation.push(zip);
  }

  if (fullLocation.length <= 1) {
    fullLocation[0] = US_STATE_HASH_SHORT_TO_LONG[fullLocation[0]];
  }

  fullLocation = fullLocation.join(", ");

  // If we are a neighborhood, don't attach the city / state
  if (neighborhood) {
    fullLocation = neighborhood;
  }

  return { fullLocation, location };
};

export function parseURLParam(param) {
  return param?.replace(/-/g, " ").toLowerCase();
}

export const parseParams = ({
  state,
  city,
  area,
  areaIdentifier,
  bounds,
  center,
}) => {
  let initialSearch = "";
  let zip = null;
  let neighborhood = null;
  let parsedBounds = {};
  let parsedCenter = [];
  if (area === "neighborhood") {
    neighborhood = capitalize(areaIdentifier.toLowerCase());
    initialSearch += `${neighborhood}, `;
  } else {
    neighborhood = null;
  }
  if (city) {
    city = capitalize(parseURLParam(city.toLowerCase()));
    initialSearch += `${city}, `;
  } else {
    city = null;
  }

  if (state) {
    state = state.toUpperCase();
    initialSearch += `${state} `;
  }

  if (area === "zip") {
    zip = areaIdentifier.toLowerCase();
    initialSearch += `, ${areaIdentifier.toLowerCase()}`;
  } else {
    zip = null;
  }

  if (bounds) {
    let boundsArray = bounds.split(",");
    parsedBounds.sw = {};
    parsedBounds.ne = {};
    parsedBounds.sw.lat = boundsArray[0];
    parsedBounds.sw.lng = boundsArray[1];
    parsedBounds.ne.lat = boundsArray[2];
    parsedBounds.ne.lng = boundsArray[3];
  }

  if (center) {
    let centerArray = center.split(",");

    parsedCenter = centerArray;
  }

  return {
    state,
    city,
    zip,
    neighborhood,
    initialSearch,
    bounds: parsedBounds,
    center: parsedCenter,
  };
};

export const getValue = (value) => {
  let number = numeral(value).value();
  let stringValue = value;
  let factor = 1;
  if (typeof value !== String) {
    stringValue = value.toString();
  }

  if (stringValue.toLowerCase().includes("k")) {
    factor = 1000;
  } else if (stringValue.toLowerCase().includes("m")) {
    factor = 1000000;
  } else if (stringValue.toLowerCase().includes("b")) {
    factor = 100000000;
  }

  return number * factor;
};
