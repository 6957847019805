import * as React from "react";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

const BugsnagInit = ({ releaseStage }) => {
  return Bugsnag.start({
    apiKey: "0c841f2a34c05d1c604e3fbd27ffe423",
    plugins: [new BugsnagPluginReact(React)],
    enabledReleaseStages: ["production", "staging"],
    releaseStage: releaseStage && releaseStage,
  });
};

export default BugsnagInit;
