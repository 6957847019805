const colors = require("tailwindcss/colors");
export const COLORS = {
  REA_BLUE: (opacity = 1) => `rgba(30, 41, 59, ${opacity})`,
  BLUE: (opacity = 1) => `rgba(51, 102, 255, ${opacity})`,
  BLACK: (opacity = 1) => `rgba(39, 39, 39, ${opacity})`,
  GREY: (opacity = 1) => `rgba(248, 248, 248, ${opacity})`,
  YELLOW: (opacity = 1) => `rgba(249, 199, 101, ${opacity})`,
  RED: (opacity = 1) => `rgba(234, 88, 12, ${opacity})`, //`rgba(235, 117, 91, ${opacity})`,
  LIGHT_BLUE: (opacity = 1) => `rgba(140, 169, 255, ${opacity})`,
  LIGHT_GREY: (opacity = 1) => `rgba(247, 248, 251, ${opacity})`,
  THERMO_RED: (opacity = 1) => `rgba(235, 19, 19, ${opacity})`,
  ORANGE: (opacity = 1) => `rgba(245, 128, 39, ${opacity})`,
  BRONZE: (opacity = 1) => `rgba(167, 112, 68, ${opacity})`,
  SILVER: (opacity = 1) => `rgba(167, 167, 173, ${opacity})`,
  GOLD: (opacity = 1) => `rgba(214, 175, 54, ${opacity})`,
  DARK_BLUE: (opacity = 1) => `rgba(30, 18, 151, ${opacity})`,
  SALMON: (opacity = 1) => `rgba(245, 233, 231, ${opacity})`,
  BLUE_GREY: (opacity = 1) => `rgb(203, 213, 225, ${opacity})`,
  PRIMARY_ACTION: (opacity = 1) => `rgba(234, 88, 12, ${opacity})`,
  GREEN: (opacity = 1) => `rgba(4, 120, 87, ${opacity});`,
  PRIME_BLUE: (opacity = 1) => `rgba(30, 41, 59, ${opacity})`,
  RATING_ORANGE: (opacity = 1) => `rgba(245, 158, 11, ${opacity})`,
  PRIME_GREY_BLUE: (opacity = 1) => `rgba(71, 85, 105, ${opacity})`,
  DARK_GREEN: (opacity = 1) => `rgba(4, 120, 87, ${opacity})`,

  // PRIMARY_ACTION: (opacity = 1) => {
  //   let rgb = hexToRgb(colors.orange["600"]);
  //   return `rgb(${rgb.r}, ${rgb.g}, ${rgb.g}, ${opacity})`;
  // },
};

export const STYLES = {
  BOX_SHADOW_BOTTOM: (opacity = 0.1) =>
    `0 4px 2px -2px ${COLORS.BLACK(opacity)}`,
  BOTTOM_RIGHT_SHADOW: (opacity = 0.1) =>
    `3px 4px 5px -2px ${COLORS.BLACK(opacity)}`,
};

export const NAV_DESKTOP_HEIGHT = 100;
export const NAV_MOBILE_HEIGHT = 64;
