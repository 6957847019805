import { capitalize, formatNumber, formatURLParam } from "./TextProcessing";
import { US_STATE_HASH_SHORT_TO_LONG, AWARD_KEYS } from "./Constants";
import { parseLocation } from "./ParseValues";
import AchievementBadges from "../components/Agents/AchievementBadges";
import { parseFullName } from "parse-full-name";
import dayjs from "dayjs";
import dayjs_utc from "dayjs/plugin/utc";
import dayjs_timezone from "dayjs/plugin/timezone";

import { css, StyleSheet } from "aphrodite";
export const renderAboutAgent = ({ agent, city, state }) => {
  if (agent.headline) {
    return agent.headline;
  }
  let score_text = "";
  const fullName = parseFullName(agent.display_name);
  if (!fullName) {
    return null;
  }
  let firstName = fullName.first;
  let listingRepresentation = "";
  let totalListings = agent.display_transactions_all_time; //agent.stats.single_family_count + agent.stats.multi_family_count;

  if (agent.single_family_pct > agent.multi_family_pct) {
    listingRepresentation = "single family homes";
  } else {
    listingRepresentation = "multi family homes";
  }

  if (agent && agent.agent_score > 0.8) {
    score_text = "a top producing";
  } else if ((agent && agent.agent_score < 0.5) || totalListings <= 5) {
    score_text = "a";
  } else {
    score_text = "an active";
  }

  let agentArray = [];

  function sortFactors(x, y) {
    if (x.score < y.score) {
      return 1;
    }
    if (x.score > y.score) {
      return -1;
    }
  }
  let score_keys = [
    "price_avg_rnk",
    "listings_all_rnk",
    "sold_not_reduced_rate_rnk",
    "price_reduced_rate_avg_rank",
    "sold_days_avg_rnk",
    "days_on_market_median_rnk",
    "agent_score",
  ];

  const upperCaseState = state?.toUpperCase();
  const hashState = US_STATE_HASH_SHORT_TO_LONG[upperCaseState];
  score_keys.reduce((result, key) => {
    if (key.includes("rnk")) {
      let description = "";
      if (key === "listings_all_rnk") {
        description = `${firstName} is one of the top listing agents in their area,
        selling more homes than ${Math.round(
          agent[key] * 100
        )}% of real estate agents in
        ${hashState}.`;
      } else if (key === "days_on_market_median_rnk") {
        description = `Based on comparable agents we rate ${firstName} as
        a fast selling agent, selling homes faster than ${Math.round(
          agent[key] * 100
        )}%
        of real estate agents in ${hashState}.`;
      } else if (key === "sold_days_avg_rnk") {
        // description = null;
      } else if (key === "sold_not_reduced_rate_rnk") {
        description = `${firstName} is a very effective listing agent selling homes without a price reduction more
        than ${Math.round(
          agent[key] * 100
        )}% of other real estate agents in ${hashState}.`;
      } else if (key === "price_avg_rnk") {
        description = `${firstName} is an elite real estate agent, representing more high-end residences than
        ${Math.round(
          agent[key] * 100
        )}% of other real estate agents in ${hashState}.`;
      } else if (key === "price_reduced_rate_avg_rnk") {
        description = `${firstName} is an excellent negotiator, having fewer price reductions on
        their listings than ${Math.round(
          agent[key] * 100
        )}% of similar real estate agents in ${hashState}.`;
      }
      if (description) {
        agentArray.push({ key, score: agent[key], description });
      }
    }
  });
  agentArray.sort(sortFactors);

  let largestFactors = "";
  let factorsIncluded = 0;
  for (let i = 0; i < agentArray.length; i++) {
    if (agentArray[i].score > 0.5) {
      if (agentArray[i].key !== "sold_dats_avg_rnk") {
        largestFactors += agentArray[i].description += " ";
        factorsIncluded += 1;
        if (factorsIncluded === 2) {
          break;
        }
      }
    }
  }
  let about = `${capitalize(
    agent.display_name
  )} is ${score_text} Real Estate Agent in ${parseLocation({
    city,
    state,
  }).fullLocation.trim()}${
    agent.display_brokerage
      ? ` with the brokerage ${capitalize(
          agent.display_brokerage.toLowerCase(),
          ["/"]
        )}`
      : ""
  }. Over the last ${
    agent.display_years_experience > 0
      ? `${Math.ceil(agent.display_years_experience)} years`
      : "year"
  }
    we have tracked ${totalListings} MLS listings by ${firstName}. ${
    agent.price_avg
      ? `${firstName} mostly represents ${listingRepresentation} ${
          agent.price_avg < 100000 //less than $100k
            ? "under $100k"
            : "around " +
              formatNumber({
                data: agent.price_avg,
                type: "currency",
              })
        }.`
      : ""
  } ${largestFactors}
  `;

  if (about === null || about === "") {
    about = "N/A";
  }
  about = about.replace(/[\r\n\x0B\x0C\u0085\u2028\u2029]+/g, " ");
  return about;
};

export const formatRealEstateAgentsLocationURL = ({ city, state }) => {
  let location = formatURLParam(`${city}`);

  return {
    path: `/[state_slug]/[city_slug]`,
    url: `/${state.toLowerCase()}/${location}`,
  };
};

export const formatAgentProfileURL = ({ url_path }) => {
  return {
    path: "/[state_slug]/[city_slug]/[agent_slug]",
    url: url_path,
  };
};

export const formatPhotoUrl = (url) => {
  return url.replace(
    "s3.amazonaws.com/agentmachine_development_pclip",
    "agentmachine-production.s3.us-west-1.amazonaws.com"
  );
};

export const renderAchievementBadges = ({
  summarize,
  agent,
  achievementStyles,
}) => {
  let awards = [];
  if (agent.prime !== null) {
    return awards;
  }
  let keys = AWARD_KEYS;
  const styles = StyleSheet.create({
    award: {
      marginRight: 8,
      marginBottom: 8,
      boxSizing: "border-box",

      "@media only screen and (max-width: 1200px)": {
        width: "100%",
      },
    },
    summarizeAward: {
      alignItems: "center",
      display: "flex",

      "@media only screen and (max-width: 767px)": {
        width: "50%",
        boxSizing: "border-box",
      },
    },
  });

  for (let i = 0; i < keys.length; i++) {
    let award = (
      <div
        className={css(styles.award, achievementStyles)}
        key={`award_${keys[i]}_${i}`}
      >
        <AchievementBadges scoreKey={keys[i]} value={agent[keys[i]]} />
      </div>
    );

    if (agent[keys[i]] >= 0.9) {
      if (
        keys[i] === "altos_agent_id" ||
        keys[i] === "state" ||
        keys[i] === "price_reduced_rate_avg_rnk" ||
        keys[i] === "days_on_market_median_rnk"
      ) {
      } else {
        awards.push(award);
      }
    }
  }

  return awards;
};

export const achievementBadgesExist = ({ agent }) => {
  let keys = AWARD_KEYS;
  let exists = false;
  if (agent.prime !== null) {
    return false;
  }
  for (let i = 0; i < keys.length; i++) {
    if (agent[keys[i]] >= 0.9) {
      if (
        keys[i] !== "altos_agent_id" &&
        keys[i] !== "state" &&
        keys[i] !== "price_reduced_rate_avg_rnk" &&
        keys[i] !== "days_on_market_median_rnk"
      ) {
        exists = true;
        break;
      }
    }
  }
  return exists;
};

export const calculateBusinessHours = ({
  hours,
  timezone = "America/New_York",
}) => {
  let times = "";
  let openNow = false;
  let closed = false;
  let noTimes = true;
  dayjs.extend(dayjs_utc);
  dayjs.extend(dayjs_timezone);

  if (hours && hours.length > 0 && timezone) {
    const newDay = dayjs().tz(timezone);
    const today = hours[newDay.day()];
    if (today.always_open_closed) {
      times = "24 hours";
      openNow = true;
      noTimes = false;
    } else if (today.always_open_closed === false) {
      closed = true;
      openNow = false;
      noTimes = false;
      times = newDay.format("dddd");
    } else if (today.open && today.close) {
      /* Remove timezone at end of open/close time strings */
      let open_array = today.open.split("-");
      let close_array = today.close.split("-");
      open_array.pop();
      close_array.pop();

      const open = dayjs.utc(open_array.join("-"));
      const close = dayjs.utc(close_array.join("-"));
      closed = false;
      noTimes = false;
      times = `${open.format("h:mm A")} - ${close.format("h:mm A")}`;
    } else {
      noTimes = true;
    }
  }
  return { times, openNow, closed, noTimes };
};

export const achievementsExist = ({ achievements }) => {
  if (achievements) {
    return achievements.length > 0;
  }
  return false;
};
