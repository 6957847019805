import { Fragment, useEffect, useState } from "react";
import { StyleSheet, css } from "aphrodite";
import ReactRating from "react-rating";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { COLORS } from "~/config/themes";

const ReviewRatings = (props) => {
  let {
    onRatingChange,
    onRatingHover,
    ratingHover,
    rating,
    fractions,
    showDescription,
    readOnly,
    starSize,
  } = props;

  const calculateRating = () => {
    if (rating === null || rating === 0) {
      return 0;
    } else if (rating >= 4.8) {
      return 5;
    } else if (rating >= 4.2) {
      return 4.5;
    } else if (rating >= 3.8) {
      return 4;
    } else if (rating >= 3.2) {
      return 3.5;
    } else if (rating >= 2.8) {
      return 3;
    } else if (rating >= 2.2) {
      return 2.5;
    } else if (rating >= 1.8) {
      return 2;
    } else if (rating >= 1.2) {
      return 1.5;
    } else if (rating < 1.2) {
      return 1;
    }
  };

  const [calculatedRating, setCalculatedRating] = useState(calculateRating);

  useEffect(() => {
    setCalculatedRating(calculateRating);
  }, [rating]);

  const renderRatingDescription = () => {
    let description = "- Select your rating";
    if (ratingHover) {
      if (ratingHover === null || ratingHover === 0) {
        description = "- Select your rating";
      } else if (ratingHover <= 1.5) {
        description = "Not good";
      } else if (ratingHover <= 2.5) {
        description = "Could've been better";
      } else if (ratingHover <= 3.5) {
        description = "Ok";
      } else if (ratingHover <= 4.5) {
        description = "Good";
      } else if (ratingHover <= 5) {
        description = "Great";
      } else {
        description = "- Select your rating";
      }
    } else {
      if (calculatedRating === null || calculatedRating === 0) {
        description = "- Select your rating";
      } else if (calculatedRating <= 1.5) {
        description = "Not good";
      } else if (calculatedRating <= 2.5) {
        description = "Could've been better";
      } else if (calculatedRating <= 3.5) {
        description = "Ok";
      } else if (calculatedRating <= 4.5) {
        description = "Good";
      } else if (calculatedRating <= 5) {
        description = "Great";
      } else {
        description = "- Select your rating";
      }
    }
    return description;
  };

  const starsColor = () => {
    let style = null;
    if (ratingHover) {
      if (ratingHover <= 1.5) {
        style = styles.ratingOne;
      } else if (ratingHover <= 2.5) {
        style = styles.ratingTwo;
      } else if (ratingHover <= 3.5) {
        style = styles.ratingThree;
      } else if (ratingHover <= 4.5) {
        style = styles.ratingFour;
      } else if (ratingHover <= 5) {
        style = styles.ratingFive;
      } else {
        style = null;
      }
    } else {
      if (calculatedRating <= 1.5) {
        style = styles.ratingOne;
      } else if (calculatedRating <= 2.5) {
        style = styles.ratingTwo;
      } else if (calculatedRating <= 3.5) {
        style = styles.ratingThree;
      } else if (calculatedRating <= 4.5) {
        style = styles.ratingFour;
      } else if (calculatedRating <= 5) {
        style = styles.ratingFive;
      } else {
        style = null;
      }
    }
    return style;
  };

  return (
    <Fragment>
      <ReactRating
        emptySymbol={
          <FontAwesomeIcon
            icon={["fas", "star"]}
            alt="review star rating"
            className={css(styles.starIcon, styles.ratingEmpty, starSize)}
          />
        }
        fullSymbol={
          <FontAwesomeIcon
            icon={["fas", "star"]}
            alt="review star rating"
            className={css(
              styles.starIcon,
              styles.ratingFull,
              starsColor(),
              starSize
            )}
          />
        }
        //onClick={onRatingChange}
        initialRating={calculatedRating}
        onChange={onRatingChange}
        onHover={onRatingHover}
        readonly={readOnly}
        fractions={fractions}
      />
      {showDescription && (
        <span className={css(styles.ratingDescription)}>
          {renderRatingDescription()}
        </span>
      )}
    </Fragment>
  );
};

const styles = StyleSheet.create({
  inputField: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 15,
  },
  inputLabel: {
    fontWeight: 500,
    fontSize: 16,
    marginBottom: 5,
  },
  starIcon: {
    fontSize: 24,
    background: "transparent",
  },
  ratingEmpty: {
    color: "#ddd",
  },
  ratingFull: {
    color: COLORS.ORANGE(),
  },
  ratingContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  ratingDescription: {
    marginLeft: 10,
  },
  ratingOne: {
    color: COLORS.YELLOW(),
  },
  ratingTwo: {
    color: "#f8b556",
  },
  ratingThree: {
    color: "#f7a346",
  },
  ratingFour: {
    color: "#f69237",
  },
  ratingFive: {
    color: COLORS.ORANGE(),
  },
});

export default ReviewRatings;
