import API from "~/config/api";
import Helpers from "~/config/helpers";
import Bugsnag from "@bugsnag/js";
import { setLeadUUID } from "./UUID";
import { parseCookies, setCookie } from "nookies";
import queryString from "query-string";

export const postAgentReview = ({
  altos_agent_id,
  agent_id,
  canonical_aid,
  client_name,
  property_address,
  client_city,
  client_state,
  client_zip,
  client_type,
  review,
  rating,
  service_year,
  service_month,
  service_date,
  client_email,
  current_url,
  service_provided,
  agent_profile_review_requests_uuid,
}) => {
  let url = API.postAgentReview({});
  return fetch(
    url,
    API.POST_CONFIG({
      data: {
        review: {
          altos_agent_id,
          agent_id,
          canonical_aid,
          client_name,
          property_address,
          client_city,
          client_state,
          client_zip,
          client_type,
          review,
          rating,
          service_year,
          service_month,
          service_date,
          client_email,
          url: current_url,
          service_provided,
          agent_profile_review_requests_uuid,
        },
      },
    })
  )
    .then(Helpers.checkStatus)
    .then(Helpers.parseJSON)
    .then((resp) => {
      return resp;
    });
};

/**
 * Fetch data for real time market data
 * @param {state} -- state to parse from url
 * @param {city} -- city to parse from url
 * @param {zip} -- zip to parse from url
 * @param {neighborhood} -- neighborhood to parse from url
 */
export const fetchLocationStats = ({ state, city, zip, neighborhood }) => {
  return fetch(
    API.getLocationStats({
      state: state,
      city: city,
      zip: zip,
      neighborhood: neighborhood,
    }),
    API.GET_CONFIG({})
  )
    .then(Helpers.checkStatus)
    .then(Helpers.parseJSON)
    .then((resp) => {
      return resp;
    });
};

/**
 * Post analytics of action tracking
 * @param { String } action -- action being recorded
 * @param { Object } action_details -- extra action information being passed
 * @param { String } lead_uuid -- tar lead id from email
 * @param { String } session_uuid -- session uuid stored in localstorage
 * @param { String } query_parameters -- query parameters
 */
export const postAnalytics = ({
  action,
  action_details,
  lead_uuid,
  session_uuid,
  query_parameters,
}) => {
  return fetch(
    API.postAnalytics(),
    API.POST_CONFIG({
      data: {
        action,
        action_details,
        lead_uuid,
        session_uuid,
        query_parameters,
      },
    })
  ).then(Helpers.checkStatus);
};

function getPathFromUrl(url) {
  const urlObj = new URL(url);
  return urlObj.pathname;
}

export const fetchCityNarrative = ({ slug, ctx }) => {
  return fetch(API.getCityNarrative({ slug, ctx }), API.GET_CONFIG({ ctx }))
    .then(Helpers.checkStatus)
    .then(Helpers.parseJSON)
    .then((resp) => {
      return resp;
    });
};

export const fetchPrivacyPolicy = ({ target, language, countryCode, ctx }) => {
  return fetch(
    API.getPrivacyPolicy({ target, language, countryCode, ctx }),
    API.GET_CONFIG({ ctx })
  )
    .then(Helpers.checkStatus)
    .then(Helpers.parseJSON)
    .then((resp) => {
      return resp;
    });
};

export const fetchTermsOfUse = ({ target, language, countryCode, ctx }) => {
  return fetch(
    API.getTermsOfUse({ target, language, countryCode, ctx }),
    API.GET_CONFIG({ ctx })
  )
    .then(Helpers.checkStatus)
    .then(Helpers.parseJSON)
    .then((resp) => {
      return resp;
    });
};

export const fetchDoNotCallPolicy = ({
  target,
  language,
  countryCode,
  ctx,
}) => {
  return fetch(
    API.getDoNotCallPolicy({ target, language, countryCode, ctx }),
    API.GET_CONFIG({ ctx })
  )
    .then(Helpers.checkStatus)
    .then(Helpers.parseJSON)
    .then((resp) => {
      return resp;
    });
};

/**
 * interest in claiming agent profile
 * @param { String } email -- email of user interested in agent profile
 * @param { String } slug -- agent slug user clicked on to get to claim profile page
 */
export const claimProfileInterest = ({ email, slug }) => {
  return fetch(
    API.claimProfileInterest({}),
    API.POST_CONFIG({ data: { email, slug } })
  )
    .then(Helpers.checkStatus)
    .then((resp) => {});
};

export const getGoogleLocationData = ({ parameters, ctx }) => {
  return fetch(
    API.googleLocationData({ parameters, ctx }),
    API.GET_CONFIG({ ctx })
  )
    .then(Helpers.checkStatus)
    .then(Helpers.parseJSON)
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return {};
    });
};
