import API from "~/config/api";
import Helpers from "~/config/helpers";

const Lightning = (uuid) => {
  return fetch(
    API.checkLightning({}),
    API.POST_CONFIG({ data: { lead: { uuid: uuid } } })
  )
    .then(Helpers.checkStatus)
    .then(Helpers.parseJSON)
    .then((resp) => {
      return resp;
    });
};

export const sendTwoFA = (uuid, phone, resend = false) => {
  return fetch(
    "/api/next/send_twofa",
    API.POST_CONFIG({ data: { uuid, phone, resend } })
  )
    .then(Helpers.checkStatus)
    .then(Helpers.parseJSON)
    .then((resp) => {
      return resp;
    });
};

export const submitTwoFA = (payload, code) => {
  return fetch(
    "/api/next/submit_twofa",
    API.POST_CONFIG({ data: { payload, code } })
  );
};

export default Lightning;
