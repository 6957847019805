import { getLocationExists } from "./Fetch/Location";
import Bugsnag from "@bugsnag/js";

export const googlePlacesLoaded = () => {
  return (
    typeof window !== "undefined" &&
    window.google &&
    window.google.maps &&
    window.google.maps.places
  );
};

export const checkLocation = async ({ city_slug }) => {
  let locationExists = await getLocationExists({
    city_slug,
  });
  if (!locationExists) {
    return { statusCode: 404 };
  } else {
    return { statusCode: 200 };
  }
};

export const notifyBugSnag = ({ error, metadata }) => {
  Bugsnag.notify(new Error(error), function (event) {
    if (metadata) {
      metadata.map((meta, index) => {
        event.addMetadata(meta.label, {
          ...meta.fields,
        });
      });
    }
  });
  console.log(error);
};

export const isError = (error) => {
  return error.statusCode < 200 || error.statusCode > 300;
};
