import { useRef, useState, useEffect } from "react";
import { StyleSheet, css } from "aphrodite";
import { geocodeByPlaceId, getLatLng } from "react-places-autocomplete";
import useGoogleMaps from "~/utils/useGoogleMaps";

import Input from "~/components/Form/Input";

import { COLORS } from "~/config/themes";
import CustomPlacesAutocomplete from "./CustomPlacesAutocomplete";

const GooglePlacesSearch = (props) => {
  const {
    locationDropdownStyle,
    containerStyle,
    className,
    inputStyle,
    handleChange,
    required,
    searchValue,
    placeholder,
    options,
    setAddressComponents,
    onPlaceSelected,
    submitForm,
    icon,
    searchButton,
    iconStyles,
    inputGroup,
    onLocationSelect,
  } = props;

  const [internalAddressComponents, setInternalAddressComponents] = useState(
    null
  );
  const [addressExists, setAddressExists] = useState(true);
  const [openSuggestions, setOpenSuggestions] = useState(false);

  const placesRef = useRef(null);

  const { isLoaded, error } = useGoogleMaps();

  const handleFocus = () => {
    setOpenSuggestions(true);
  };

  useEffect(() => {
    const handleClick = (e) => {
      if (placesRef.current && !placesRef.current.contains(e.target)) {
        setOpenSuggestions(false);
      } else {
        setOpenSuggestions(true);
      }
    };

    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const handlePlaceSelect = (address, placeId, suggestion) => {
    setAddressExists(true);
    if (!placeId) {
      setAddressExists(false);
      return;
    }
    geocodeByPlaceId(placeId).then((results) => {
      const addressObject = results[0];
      let address_components = addressObject.address_components;
      let addressParams = {};
      for (let i = 0; i < address_components.length; i++) {
        if (address_components[i].types.includes("street_number")) {
          addressParams.street_number = address_components[i].long_name;
        } else if (address_components[i].types.includes("route")) {
          addressParams.street_name = address_components[i].long_name;
        } else if (address_components[i].types.includes("locality")) {
          addressParams.city = address_components[i].long_name;
        } else if (
          address_components[i].types.includes("administrative_area_level_1")
        ) {
          addressParams.state = address_components[i].short_name;
        } else if (address_components[i].types.includes("postal_code")) {
          addressParams.zip = address_components[i].long_name;
        } else if (address_components[i].types.includes("country")) {
          addressParams.country = address_components[i].short_name;
        }

        if (
          !addressParams.city &&
          address_components[i].types.includes("administrative_area_level_2")
        ) {
          addressParams.city = address_components[i].long_name;
        }
      }

      if (!addressParams.city) {
        addressParams.city = addressObject.formatted_address;
      }

      handleChange(address.replace(", USA", ""));
      getLatLng(addressObject).then(async (coordinates) => {
        addressParams.lat = coordinates.lat;
        addressParams.lng = coordinates.lng;
        addressParams.lat_lng = `${addressParams.lat}, ${addressParams.lng}`;

        if (!addressParams.hasOwnProperty("zip")) {
          await geocodeByLatLng(coordinates).then((results) => {
            const result = results.find((item) =>
              item?.address_components.find((item) =>
                item?.types.includes("postal_code")
              )
            ); // Find the first item with postal code
            if (result !== undefined) {
              addressParams.zip = result.address_components.find((item) =>
                item.types.includes("postal_code")
              ).long_name;
            }
          });
        }

        setInternalAddressComponents(addressParams);
        if (setAddressComponents) {
          setAddressComponents(addressParams);
        }
        if (onLocationSelect) {
          onLocationSelect({
            addressParams,
            address: address.replace(", USA", ""),
          });
        }

        onPlaceSelected && onPlaceSelected();
      });
    });
  };

  const onInputChange = (address) => {
    handleChange(address.replace(", USA", ""));
  };

  const searchOptions = {
    types: ["address"],
    componentRestrictions: { country: ["us", "ca"] },
  };

  const geocodeByLatLng = (latLng) => {
    const geocoder = new window.google.maps.Geocoder();
    const OK = window.google.maps.GeocoderStatus.OK;

    return new Promise((resolve, reject) => {
      geocoder.geocode({ location: latLng }, (results, status) => {
        if (status !== OK) {
          reject(status);
        }
        resolve(results);
      });
    });
  };

  return (
    <>
      {isLoaded && (
        <CustomPlacesAutocomplete
          value={searchValue}
          onChange={onInputChange}
          onSelect={handlePlaceSelect}
          highlightFirstSuggestion={true}
          searchOptions={options ? options : searchOptions}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps }) => {
            return (
              <div
                className={css(
                  styles.locationSearchContainer,
                  props.containerStyle && props.containerStyle
                )}
                ref={placesRef}
              >
                <Input
                  {...getInputProps({
                    onFocus: handleFocus,
                    containerStyle: containerStyle,
                    className: className,
                    inputGroup: inputGroup,
                    inputStyle: inputStyle,
                    required: required,
                    name: "google_places_search",
                    placeholder: placeholder,
                    icon: icon,
                    iconStyles: iconStyles,
                    searchButton: searchButton,
                    autoComplete: "new-password",
                    type: "google_places_autocomplete",
                    onKeyDown: (e) => {
                      if (e.which === 13 || e.which === 9) {
                        if (internalAddressComponents) {
                          submitForm && submitForm(e);
                        } else if (!addressExists) {
                          submitForm && submitForm(e);
                        }
                      } else {
                        setInternalAddressComponents(null);
                        setAddressComponents({});
                        setAddressExists(true);
                      }
                    },
                  })}
                />
                <div
                  className={css(
                    styles.locationDropdown,
                    locationDropdownStyle
                  )}
                >
                  {openSuggestions &&
                    suggestions.length > 0 &&
                    suggestions
                      .filter(
                        (suggestion) => !suggestion.types.includes("route")
                      )
                      .map((suggestion, index) => {
                        const className = suggestion.active
                          ? "suggestion-item--active"
                          : "suggestion-item";
                        return (
                          <div
                            key={`google_autocomplete_suggestion_div_${index}`}
                            {...getSuggestionItemProps(suggestion, {
                              className: css(styles.suggestion),
                              key: `google_autocomplete_suggestion_${index}`,
                            })}
                          >
                            <span>
                              {suggestion.description.replace(", USA", "")}
                            </span>
                          </div>
                        );
                      })}
                </div>
              </div>
            );
          }}
        </CustomPlacesAutocomplete>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  locationSearchContainer: {
    width: "100%",
    zIndex: 2,
    borderRadius: 2,
    position: "relative",
    overflowY: "visible",

    "@media only screen and (max-width: 425px)": {
      minWidth: 0,
    },
  },

  locationDropdown: {
    width: "100%",
    boxShadow: "3px 3px 10px 1px #eee",
    background: "#fff",
    position: "absolute",
    color: "#000",
    zIndex: 2,
    textAlign: "left",

    "@media only screen and (max-width: 767px)": {
      overflow: "scroll",
      maxHeight: "calc(100vh - 100px)",
    },
  },
  suggestion: {
    padding: "12px 20px",
    cursor: "pointer",
    color: COLORS.BLACK(),
    textDecoration: "none",

    ":hover": {
      background: COLORS.GREY(1),
    },

    "@media only screen and (max-width: 767px)": {
      padding: 16,
      background: "#fff",
    },
  },
});

export default GooglePlacesSearch;
