import { setCookie, parseCookies } from "nookies";
import { v4 as uuidv4 } from "uuid";
import queryString from "query-string";

export const clearLeadUUID = () => {
  localStorage.removeItem("lead_uuid");
  setCookie(null, "lead_uuid", "", {
    path: "/",
  });
};

export const setLeadUUID = () => {
  let new_uuid = uuidv4();
  localStorage.setItem("lead_uuid", new_uuid);
  setCookie(null, "lead_uuid", new_uuid, {
    path: "/",
  });

  return new_uuid;
};

export const getLeadUUID = (skipSetUUID, forceUUID) => {
  if (forceUUID) {
    return forceUUID;
  }
  const cookies = parseCookies();
  let uuid = cookies.lead_uuid;

  if (process.browser) {
    uuid = localStorage.getItem("lead_uuid");

    if (uuid === null && !skipSetUUID) {
      uuid = setLeadUUID();
    }
  }
  return uuid;
};

export const setLeadCreatedUUID = (uuid) => {
  localStorage.setItem("lead_created_uuid", uuid);
  setCookie(null, "lead_created_uuid", uuid, {
    path: "/",
  });
};

export const setSessionUUID = () => {
  let new_uuid = uuidv4();
  const cookies = parseCookies();

  if (cookies.rea_session_uuid) {
    new_uuid = cookies.rea_session_uuid;
  } else {
    setCookie(null, "rea_session_uuid", new_uuid, {
      path: "/",
    });
  }

  localStorage.setItem("rea_session_uuid", new_uuid);

  return new_uuid;
};

export const createUUID = ({ isServer, ctx }) => {
  if (!isServer) {
    let localStorage = window.localStorage;
    let session_uuid = localStorage.getItem("rea_session_uuid");
    let favorite_agents = localStorage.getItem("favorite_agents");
    let { lead_uuid } = queryString.parse(location.search);

    if (!lead_uuid) {
      lead_uuid = localStorage.getItem("lead_uuid");
    }
    const cookies = parseCookies();

    if (!session_uuid) {
      if (cookies.rea_session_uuid) {
        session_uuid = cookies.rea_session_uuid;
        localStorage.setItem("rea_session_uuid", session_uuid);
      }
    }

    if (!favorite_agents) {
      if (cookies["favorite-agents"]) {
        favorite_agents = cookies["favorite-agents"];
        localStorage.setItem("favorite_agents", favorite_agents);
      }
    }

    let new_uuid = session_uuid;
    if (!session_uuid && lead_uuid) {
      new_uuid = uuidv5(lead_uuid, uuid_custom_namespace);
      localStorage.setItem("rea_session_uuid", new_uuid);

      setCookie(null, "rea_session_uuid", new_uuid, {
        path: "/",
      });
      setCookie(null, "lead_uuid", lead_uuid, {
        path: "/",
      });
    } else if (!session_uuid) {
      new_uuid = uuidv4();
      session_uuid = new_uuid;
      localStorage.setItem("rea_session_uuid", new_uuid);
      setCookie(null, "rea_session_uuid", new_uuid, {
        path: "/",
      });
    }

    return { session_uuid, lead_uuid, favorite_agents };
  }

  return { session_uuid: null };
};
