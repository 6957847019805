import PropTypes from "prop-types";
import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  createRef,
} from "react";
import Input from "~/components/Inputs/Input.js";

const Select = (props) => {
  const {
    label,
    children,
    helpText,
    className,
    wrapperClassName,
    onChange,
    customErrorMessage,
    showError,
    value,
    onFocus,
    searchable,
    inlineFunnel,
  } = props;
  const [active, setActive] = useState(false);
  // we only want options
  const filteredChildren = children.filter(function (e) {
    return e.type === "option";
  });

  const [internalLabel, setInternalLabel] = useState("");
  const [internalValue, setInternalValue] = useState("");
  const [currentIndex, setCurrentIndex] = useState(null);
  const [search, setSearch] = useState("");

  const dropdownEl = useRef();
  const dropdownButtonEl = useRef();
  const itemRefs = useRef([]);

  itemRefs.current = filteredChildren.map(
    (_, i) => itemRefs.current[i] ?? createRef()
  );

  const handleFocus = (e) => {
    onFocus(e);
  };

  useEffect(() => {
    document.addEventListener("click", closeDropdown);

    return () => {
      document.removeEventListener("click", closeDropdown);
    };
  }, []);

  useEffect(() => {
    if (value) {
      findAndSeekValue(value);
    }
  }, [value]);

  useEffect(() => {
    if (active) {
      document.addEventListener("keydown", iterateOptions);
      // scroll to active item in dropdown
      if (internalValue.length > 0) {
        const activeItem = itemRefs.current.find((_) =>
          _.current.classList.contains("--active")
        );
        if (activeItem != undefined) {
          activeItem.current.parentNode.scrollTop =
            activeItem.current.offsetTop - 60;
        }
      }
    } else {
      document.removeEventListener("keydown", iterateOptions);
    }
  }, [active]);

  const iterateOptions = useCallback((e) => {
    nextOption(e);
  }, []);

  const nextOption = (e) => {
    if (e.keyCode === 40 || e.keyCode === 38 || e.keyCode === 9) {
      e.preventDefault();
      const activeItem = itemRefs.current.find((_) =>
        _.current.classList.contains("--active")
      );
      let index = null;

      if (activeItem) {
        index = parseInt(activeItem.current.attributes.index.value);
      }

      switch (e.keyCode) {
        case 40:
        case 9:
          if (index === null) {
            index = 0;
          } else {
            index = (index + 1) % filteredChildren.length;
          }
          break;
        case 38:
          if (index === null) {
            index = filteredChildren.length - 1;
          } else {
            index =
              (filteredChildren.length + (index - 1)) % filteredChildren.length;
          }
          break;
      }

      setInternalLabel(filteredChildren[index].props.children);
      setInternalValue(filteredChildren[index].props.value);
      const itemElement = itemRefs.current[index].current;
      if (itemElement != undefined || itemElement != null) {
        itemElement.parentNode.scrollTop = itemElement.offsetTop - 60;
      }
    }
  };

  const findAndSeek = (needle) => {
    let found = false;
    filteredChildren.map((child, index) => {
      const label = child.props.children.toLowerCase();
      if (!found && label.substring(0, needle.length) == needle) {
        setInternalValue(child.props.value || child.props.children);
        setInternalLabel(child.props.children);
        const itemElement = itemRefs.current[index].current;
        if (itemElement != undefined || itemElement != null) {
          itemElement.parentNode.scrollTop = itemElement.offsetTop - 60;
        }

        found = true;
      }
    });
  };

  const findAndSeekValue = (needle) => {
    let found = false;
    filteredChildren.map((child, index) => {
      if (!found && child.props.value.toLowerCase() == needle.toLowerCase()) {
        setInternalValue(child.props.value || child.props.children);
        setInternalLabel(child.props.children);
        found = true;
      }
    });
  };

  useEffect(() => {
    onChange(internalValue);
  }, [internalValue]);

  const handleClick = () => {
    setActive(!active);
  };

  const handleItemClick = (item) => {
    setInternalValue(item.props.value || item.props.children);
    setInternalLabel(item.props.children);
    setActive(false);
  };

  const closeDropdown = useCallback((e) => {
    if (dropdownEl.current == null || dropdownButtonEl.current == null) return;
    if (
      !dropdownEl.current.contains(e.target) &&
      dropdownButtonEl.current != e.target
    ) {
      setActive(false);
    }
  }, []);

  return (
    <div className={`Select ${className}`}>
      <div
        className={`Select__wrapper ${wrapperClassName} ${
          active ? "--active" : ""
        } ${showError ? "--invalid" : value ? "--valid" : ""} ${
          internalLabel ? "--label-active" : ""
        }`}
      >
        <button
          type="button"
          className={`Select__button ${inlineFunnel ? "--inline-funnel" : ""}`}
          onClick={handleClick}
          ref={dropdownButtonEl}
          onFocus={handleFocus}
        >
          {internalLabel ? internalLabel : label}
        </button>
      </div>
      {active && (
        <div
          className={`Select__dropdown ${active ? "--active" : ""}`}
          ref={dropdownEl}
        >
          {searchable && (
            <div className="Select__search">
              <Input
                label={"Search"}
                name={"state-search"}
                value={search}
                autoFocus={true}
                onChange={(value) => {
                  setSearch(value);
                  findAndSeek(value.toLowerCase());
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    handleClick();
                  }
                }}
              />
            </div>
          )}
          <div>
            <ul>
              {filteredChildren.map((child, index) => (
                <li
                  key={index}
                  index={index}
                  className={
                    child.props.value === internalValue ? "--active" : null
                  }
                  ref={itemRefs.current[index]}
                >
                  <button
                    type="button"
                    className={`Select__dropdown-button ${
                      inlineFunnel ? "--inline-funnel" : ""
                    }`}
                    onClick={() => {
                      handleItemClick(child);
                    }}
                  >
                    {child.props.children}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
      {helpText && <div className="Select__help-text">{helpText}</div>}
      {showError && (
        <ul className="Input__errors">
          <li>{customErrorMessage}</li>
        </ul>
      )}
    </div>
  );
};

Select.propTypes = {
  label: PropTypes.string,
  helpText: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
};

Select.defaultProps = {
  label: "",
  helpText: "",
  className: "",
  onChange: (value) => {},
  onFocus: () => {},
};

export default Select;
