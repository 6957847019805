import { notifyBugSnag } from "../utils/ErrorChecking";
/***
 * Action / Reducer file for Errors
 */

/**********************************
 *        ACTIONS SECTION         *
 **********************************/

export const ErrorConstants = {
  SET_ERROR: "@@error/SET_ERROR",
};

export const ErrorActions = {
  /***
   * set error
   * @param {Integer} error -- set error
   */
  setError: ({ statusCode, message, metaData }) => {
    if (statusCode < 200 || statusCode > 300) {
      notifyBugSnag({ error: message, metadata: metaData });
    }
    return (dispatch) => {
      return dispatch({
        type: ErrorConstants.SET_ERROR,
        statusCode: statusCode,
      });
    };
  },
};

/**********************************
 *        REDUCER SECTION         *
 **********************************/

const defaultErrorState = {
  statusCode: 200,
};

const ErrorReducer = (state = defaultErrorState, action) => {
  switch (action.type) {
    case ErrorConstants.SET_ERROR:
      return {
        ...state,
        ...action,
      };
    default:
      return state;
  }
};

export default ErrorReducer;
