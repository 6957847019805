import API from "~/config/api";
import Helpers from "~/config/helpers";

/**
 * Fetching if location exists or not
 * @param { State } state -- state to check
 * @param { String } city -- city to check
 * @param { String } zip -- zip to check
 * @param { String } neighborhood -- neighborhood to check
 */
export const getLocationExists = ({ city_slug, ctx }) => {
  return fetch(
    API.getLocationExists({
      city_slug,
    }),
    API.GET_CONFIG({ ctx })
  )
    .then(Helpers.checkStatus)
    .then(Helpers.parseJSON)
    .then((resp) => {
      return resp;
    });
};

/**
 * Fetch data for most recent location stats
 * @param {state} -- state to parse from url
 * @param {city} -- city to parse from url
 * @param {zip} -- zip to parse from url
 * @param {neighborhood} -- neighborhood to parse from url
 * @param {setLocationStatsSummary} -- save most location stats
 * @param {isServer} -- if call is being made from server or not
 */
export const fetchMostRecentLocationStats = ({
  state,
  city,
  zip,
  neighborhood,
  isServer,
  ctx,
}) => {
  let url = API.getLocationMostRecentStats({
    state: state,
    city: city,
    zip: zip,
    neighborhood: neighborhood,
    ctx,
  });
  return fetch(url, API.GET_CONFIG({ isServer, ctx }))
    .then(Helpers.checkStatus)
    .then(Helpers.parseJSON)
    .then((resp) => {
      return resp;
    });
};

/**
 * Fetch data for real time market data
 * @param {state} -- state to parse from url
 * @param {city} -- city to parse from url
 * @param {zip} -- zip to parse from url
 * @param {neighborhood} -- neighborhood to parse from url
 * @param {page} -- pagination page to search for
 * @param {limit} -- number of items to return per page
 * @param {startDate} -- date filter to start the search from
 * @param {setMarketData} -- save market data from fetch
 * @param {isServer} -- if call is being made from server or not
 */
export const fetchTimelineLocationStats = ({
  state,
  city,
  zip,
  neighborhood,
  page,
  limit,
  startDate,
  isServer,
  ctx,
}) => {
  let url = API.getLocationTimelineStats({
    state: state,
    city: city,
    zip: zip,
    neighborhood: neighborhood,
    page,
    limit,
    startDate,
    ctx,
  });
  return fetch(url, API.GET_CONFIG({ isServer, ctx }))
    .then(Helpers.checkStatus)
    .then(Helpers.parseJSON)
    .then((resp) => {
      return resp.stats;
    });
};

/**
 * Fetch data for MAI
 * @param {state} -- state to parse from url
 * @param {city} -- city to parse from url
 * @param {zip} -- zip to parse from url
 * @param {neighborhood} -- neighborhood to parse from url
 * @param {page} -- pagination page to search for
 * @param {limit} -- number of items to return per page
 * @param {startDate} -- date filter to start the search from
 * @param {setMAI} -- save MAI from fetch
 * @param {isServer} -- if call is being made from server or not
 */
export const fetchLocationMAI = ({
  state,
  city,
  zip,
  neighborhood,
  page,
  limit,
  startDate,
  isServer,
  ctx,
}) => {
  let url = API.getLocationMAI({
    state: state,
    city: city,
    zip: zip,
    neighborhood: neighborhood,
    page,
    limit,
    startDate,
    ctx,
  });
  return fetch(url, API.GET_CONFIG({ isServer, ctx }))
    .then(Helpers.checkStatus)
    .then(Helpers.parseJSON)
    .then((resp) => {
      return resp;
    });
};

export const fetchFunnelCityStats = ({ city, state, ctx }) => {
  return fetch(
    API.getFunnelCityStats({ city, state, ctx }),
    API.GET_CONFIG({ ctx })
  )
    .then(Helpers.checkStatus)
    .then(Helpers.parseJSON)
    .then((resp) => {
      return resp.stats;
    });
};

/**
 * Fetching 1 level down contained location based on current search level
 * @param { State } state -- if we pass state, then we want to fetch the popular states
 * @param { String } city -- if we pass city, then we want to fetch the popular neighborhoods
 * @param { String } type -- nearby search type to return I.E. cities, states, zips, nbhds
 */
export const fetchContainedLocations = ({
  state,
  city,
  type,
  page,
  limit,
  detailed,
}) => {
  return fetch(
    API.getLocationNearby({
      state,
      city,
      page,
      limit,
      nearby: type,
      detailed,
    }),
    API.GET_CONFIG({})
  )
    .then(Helpers.checkStatus)
    .then(Helpers.parseJSON)
    .then((resp) => {
      return resp;
    })
    .catch((err) => {
      return { geos: [] };
    });
};

export const fetchLocations = ({ state, locales }) => {
  return fetch(
    API.SITEMAP({
      state,
      locales,
    }),
    API.GET_CONFIG({})
  )
    .then(Helpers.checkStatus)
    .then(Helpers.parseJSON)
    .then((resp) => {
      return resp;
    });
};

export const fetchHtmlSitemapStates = () => {
  return fetch(API.htmlSitemapStates(), API.GET_CONFIG({}))
    .then(Helpers.checkStatus)
    .then(Helpers.parseJSON)
    .then((resp) => {
      return resp;
    });
};

export const fetchCities = ({ state }) => {
  return fetch(
    API.listCities({
      state,
    }),
    API.GET_CONFIG({})
  )
    .then(Helpers.checkStatus)
    .then(Helpers.parseJSON)
    .then((resp) => {
      return resp;
    });
};

export const fetchCityHtmlSitemap = ({ state }) => {
  return fetch(
    API.cityHtmlSitemap({
      state,
    }),
    API.GET_CONFIG({})
  )
    .then(Helpers.checkStatus)
    .then(Helpers.parseJSON)
    .then((resp) => {
      return resp;
    });
};

export const locationSearch = ({ location, abortController }) => {
  return fetch(
    API.SEARCH_LOCATION({ location }),
    API.GET_CONFIG({ abortController })
  )
    .then(Helpers.checkStatus)
    .then(Helpers.parseJSON)
    .then((resp) => {
      return resp;
    });
};

export const googleGeocoding = ({ lat, lng }) => {
  const reverseGeocodingUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&result_type=locality&key=AIzaSyCUmh_k-H1Mv8SKlDq8KUuikx-zZ3A4_7c`;
  return fetch(reverseGeocodingUrl, { method: "GET" })
    .then(Helpers.checkStatus)
    .then(Helpers.parseJSON)
    .then((resp) => {
      return resp;
    });
};
