import { StyleSheet, css } from "aphrodite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AWARDS = {
  price_avg_rnk: {
    bronze:
      "This agent sells higher priced homes than the average California agent",
    silver:
      "This agent sells higher priced homes than 75% of agents in California",
    gold:
      "This agent sells higher priced homes than 95% of agents in California",
    icon: "/static/money-bag-award.svg",
    alt: "Sells for high price icon",
    description: "Sells for a high price",
  },
  listings_all_rnk: {
    bronze: "This agent sells more homes than the average California agent",
    silver: "This agent sells more homes than 75% of agents in California",
    gold: "This agent sells more homes than 95% of agents in California",
    icon: "/static/house-award.svg",
    alt: "high sales rate icon",
    description: "High sales rate",
  },
  sold_not_reduced_rate_rnk: {
    bronze:
      "This agent sells houses quickly, at or above list price better than the average California agent",
    silver:
      "This agent sells houses quickly, at or above list price better than 75% of agents in California",
    gold:
      "This agent sells houses quickly, at or above list price better than 95% of agents in California",
    icon: "/static/badge-award.svg",
    alt: "Least reductions in sales price icon",
    description: "Least price cuts",
  },
  sold_days_avg_rnk: {
    bronze: "This agent sells homes faster than the average California agent ",
    silver: "This agent sells homes faster than 75% of agents in California",
    gold: "This agent sells homes faster than 95% of agents in California",
    icon: "/static/time-award.svg",
    alt: "fast seller icon",
    description: "Fast seller",
  },
  agent_score: {
    bronze: "This agent is ranked in the top 20% of agents in California",
    silver: "This agent is ranked in the top 10% of agents in California",
    gold: "This agent is ranked in the top 5% of agents in California",
    icon: "/static/trophy-award.svg",
    alt: "top agent icon",
    description: "Top agent",
  },
};

const AchievementBadges = (props) => {
  let { value, scoreKey, summarize } = props;

  const renderAward = () => {
    return (
      <div className={css(styles.award)}>
        <FontAwesomeIcon
          icon={["fas", "check-circle"]}
          className={css(styles.awardIcon)}
        />
        <span
          className={css(
            styles.description,
            summarize && styles.summarizeDescription
          )}
        >
          {AWARDS[scoreKey].description}
        </span>
      </div>
    );
  };

  return <div className={css(styles.awardContainer)}>{renderAward()}</div>;
};

const styles = StyleSheet.create({
  awardContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  award: {
    display: "flex",
    alignItems: "center",
    fontSize: 14,
    fontWeight: 600,
    background: "rgba(224, 242, 254, 1)",
    padding: "4px 13px",
    borderRadius: 40,
  },
  awardIcon: {
    width: 12,
    height: 12,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 7,
    color: "rgba(0, 107, 184, 1)",
  },
  summarizeIcon: {
    height: 18,
    width: 18,
  },
  summarizeDescription: {
    fontSize: 12,
  },
  description: {
    width: "100%",
  },
});

export default AchievementBadges;
