const phoneFormats = {
  ar: { phoneFormat: "(..) ........" },
  am: { phoneFormat: ".. ......" },
  au: { phoneFormat: "(..) .... ...." },
  az: { phoneFormat: "(..) ... .. .." },
  by: { phoneFormat: "(..) ... .. .." },
  be: { phoneFormat: "... .. .. .." },
  br: { phoneFormat: "(..) ........." },
  ca: { phoneFormat: "(...) ...-...." },
  cn: { phoneFormat: "..-........." },
  co: { phoneFormat: "... ... ...." },
  cr: { phoneFormat: "....-...." },
  ci: { phoneFormat: ".. .. .. .." },
  cy: { phoneFormat: ".. ......" },
  cz: { phoneFormat: "... ... ..." },
  dk: { phoneFormat: ".. .. .. .." },
  sv: { phoneFormat: "....-...." },
  ee: { phoneFormat: ".... ......" },
  fi: { phoneFormat: ".. ... .. .." },
  fr: { phoneFormat: ". .. .. .. .." },
  de: { phoneFormat: ".... ........" },
  gt: { phoneFormat: "....-...." },
  ht: { phoneFormat: "....-...." },
  hk: { phoneFormat: ".... ...." },
  is: { phoneFormat: "... ...." },
  in: { phoneFormat: ".....-....." },
  ir: { phoneFormat: "... ... ...." },
  ie: { phoneFormat: ".. ......." },
  il: { phoneFormat: "... ... ...." },
  it: { phoneFormat: "... ......." },
  jp: { phoneFormat: ".. .... ...." },
  kz: { phoneFormat: "... ...-..-.." },
  kg: { phoneFormat: "... ... ..." },
  lv: { phoneFormat: ".. ... ..." },
  my: { phoneFormat: "..-....-...." },
  mx: { phoneFormat: "... ... ...." },
  md: { phoneFormat: "(..) ..-..-.." },
  nl: { phoneFormat: ".. ........" },
  nz: { phoneFormat: "...-...-...." },
  no: { phoneFormat: "... .. ..." },
  pk: { phoneFormat: "...-......." },
  ph: { phoneFormat: ".... ......." },
  pl: { phoneFormat: "...-...-..." },
  ru: { phoneFormat: "(...) ...-..-.." },
  sg: { phoneFormat: "....-...." },
  kr: { phoneFormat: "... .... ...." },
  es: { phoneFormat: "... ... ..." },
  se: { phoneFormat: "(...) ...-..." },
  ch: { phoneFormat: ".. ... .. .." },
  tr: { phoneFormat: "... ... .. .." },
  ua: { phoneFormat: "(..) ... .. .." },
  gb: { phoneFormat: ".... ......" },
  us: { phoneFormat: "(...) ...-...." },
  uz: { phoneFormat: ".. ... .. .." },
  va: { phoneFormat: ".. .... ...." },
};

export default phoneFormats;
