import PropTypes from "prop-types";
import React from "react";

/**
 * PropertyType
 *
 * Property type screen. Collects users property type.

* @param {*} props
 * @returns
 */
const PropertyType = (props) => {
  const {
    clientType,
    propertyType,
    setPropertyType,
    nextScreen,
    prevScreen,
  } = props;
  const screenKey = "PropertyType";

  // set context dependent text strings
  const label =
    clientType == "selling" || clientType == "buying and selling"
      ? "sell"
      : "buy";

  const handleChange = (e) => {
    setPropertyType(e.target.value);
    setTimeout(() => {
      nextScreen({ key: screenKey, value: e.target.value });
    }, 200);
  };

  return (
    <div className="MessageAgentForm__screen" key="type">
      <div className="MessageAgentForm__screen-heading">
        What type of home are you looking to {label}?
      </div>
      <div className="MessageAgentForm__screen-grid">
        <label>
          <input
            name="type"
            type="radio"
            value="single_family"
            onClick={(e) => {
              handleChange(e);
            }}
            checked={propertyType === "single_family"}
          />
          <div>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.6">
                <path
                  d="M18.7611 7.68383C18.525 7.48721 11.3449 1.50379 10.9252 1.15399C10.6789 0.948671 10.3211 0.948671 10.0748 1.15399L2.23891 7.68383C2.08755 7.81003 2 7.99693 2 8.19407V17.3359C2 17.7027 2.29727 18 2.66406 18H18.3359C18.7027 18 19 17.7027 19 17.3359V8.19407C19 7.99693 18.9125 7.81003 18.7611 7.68383ZM17.6719 10.1419H13.7761V7.55206H16.5283L17.6719 8.50509V10.1419ZM3.32812 10.1641H4.61202V12.112C4.61202 12.4788 4.90929 12.7761 5.27608 12.7761H7.22404V14.7239C7.22404 15.0907 7.52132 15.388 7.88811 15.388H9.83594V16.6719H3.32812V10.1641ZM11.1641 16.6719V14.7239C11.1641 14.3573 10.8668 14.0599 10.5 14.0599H8.55204V12.112C8.55204 11.7452 8.25477 11.448 7.88798 11.448H5.94015V9.50002C5.94015 9.13323 5.64275 8.83596 5.27608 8.83596H3.32812V8.50509L10.5 2.52854L14.9346 6.22394H13.112C12.7452 6.22394 12.448 6.52134 12.448 6.888V10.806C12.448 11.1728 12.7452 11.47 13.112 11.47H17.6719V16.6719H11.1641Z"
                  fill="#272727"
                />
              </g>
            </svg>
            Single Family
          </div>
        </label>
        <label>
          <input
            name="type"
            type="radio"
            value="condominium"
            onClick={(e) => {
              handleChange(e);
            }}
            checked={propertyType === "condominium"}
          />
          <div>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.6">
                <path
                  d="M16.2892 1H3.71083C3.31821 1 3 1.31476 3 1.70312V18.2969C3 18.6852 3.31821 19 3.71083 19H16.2892C16.6818 19 17 18.6852 17 18.2969V1.70312C17 1.31476 16.6818 1 16.2892 1ZM7.91486 17.5938V13.4688H12.0851V17.5938H7.91486ZM15.5783 17.5938H13.5068V12.7657C13.5068 12.3773 13.1886 12.0625 12.796 12.0625H7.20403C6.81154 12.0625 6.4932 12.3773 6.4932 12.7657V17.5938H4.42166V2.40625H15.5783V17.5938ZM6.4932 5.19527C6.4932 4.8069 6.81154 4.49214 7.20403 4.49214H8.60208C8.9947 4.49214 9.31291 4.8069 9.31291 5.19527C9.31291 5.58363 8.9947 5.89839 8.60208 5.89839H7.20403C6.81154 5.89839 6.4932 5.58363 6.4932 5.19527ZM6.4932 9.27339C6.4932 8.88503 6.81154 8.57027 7.20403 8.57027H8.60208C8.9947 8.57027 9.31291 8.88503 9.31291 9.27339C9.31291 9.66176 8.9947 9.97652 8.60208 9.97652H7.20403C6.81154 9.97652 6.4932 9.66176 6.4932 9.27339ZM10.6871 5.19527C10.6871 4.8069 11.0054 4.49214 11.3979 4.49214H12.796C13.1886 4.49214 13.5068 4.8069 13.5068 5.19527C13.5068 5.58363 13.1886 5.89839 12.796 5.89839H11.3979C11.0054 5.89839 10.6871 5.58363 10.6871 5.19527ZM10.6871 9.27339C10.6871 8.88503 11.0054 8.57027 11.3979 8.57027H12.796C13.1886 8.57027 13.5068 8.88503 13.5068 9.27339C13.5068 9.66176 13.1886 9.97652 12.796 9.97652H11.3979C11.0054 9.97652 10.6871 9.66176 10.6871 9.27339Z"
                  fill="#272727"
                  stroke="white"
                  strokeWidth="0.1"
                />
              </g>
            </svg>
            Condominium
          </div>
        </label>
        <label>
          <input
            name="type"
            type="radio"
            value="townhome"
            onClick={(e) => {
              handleChange(e);
            }}
            checked={propertyType === "townhome"}
          />
          <div>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.6" clipPath="url(#clip0)">
                <path
                  d="M19.7713 6.71546L15.1619 2.22307C14.8567 1.92564 14.362 1.92564 14.057 2.22307L12.3047 3.93077L10.5525 2.22307C10.2473 1.92564 9.75266 1.92564 9.44763 2.22307L7.69531 3.93077L5.94299 2.22307C5.63797 1.92564 5.14328 1.92564 4.83826 2.22307L0.228882 6.71546C0.0822449 6.85822 0 7.05185 0 7.25381V16.2386C0 16.6591 0.349731 17 0.78125 17H19.2188C19.6503 17 20 16.6591 20 16.2386V7.25381C20 7.05185 19.9178 6.85822 19.7713 6.71546ZM10 3.83827C10.4115 4.23935 13.3539 7.10703 13.8281 7.56923V15.4772H10.7812V7.25381C10.7812 7.05185 10.699 6.85822 10.5525 6.71546L8.8002 5.00761L10 3.83827ZM1.5625 7.56923L5.39062 3.83827L9.21875 7.56923V15.4772H7.70844V11.7462C7.70844 11.3256 7.3587 10.9848 6.92719 10.9848H3.85422C3.4227 10.9848 3.07297 11.3256 3.07297 11.7462V15.4772H1.5625V7.56923ZM6.14594 15.4772H4.63547V12.5076H6.14594V15.4772ZM18.4375 15.4772H15.3906V7.25381C15.3906 7.05185 15.3084 6.85822 15.1619 6.71546L13.4096 5.00761L14.6094 3.83827L18.4375 7.56923V15.4772Z"
                  fill="#272727"
                  stroke="white"
                  strokeWidth="0.1"
                />
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
            Townhome
          </div>
        </label>
        <label>
          <input
            name="type"
            type="radio"
            value="mobile_home"
            onClick={(e) => {
              handleChange(e);
            }}
            checked={propertyType === "mobile_home"}
          />
          <div>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.6" clipPath="url(#clip0)">
                <path
                  d="M20 11.2696L17.1713 9.75221L16.7634 7.46378C18.0125 7.45881 19.0272 6.53445 19.0272 5.39893C19.0272 4.84705 18.7904 4.32829 18.3605 3.93821C17.9311 3.54852 17.3604 3.33398 16.7534 3.33398H16.7514L1.75792 3.34595C0.788602 3.34677 0 4.06351 0 4.94377V15.4246H2.54257C2.79227 16.1441 3.53383 16.6673 4.40755 16.6673C5.28127 16.6673 6.02284 16.1441 6.27253 15.4246H13.462C13.7117 16.1441 14.4533 16.6673 15.327 16.6673C16.2007 16.6673 16.9423 16.1441 17.192 15.4246H20L20 11.2696ZM4.40759 15.6021C3.9764 15.6021 3.6256 15.2835 3.6256 14.892C3.6256 14.5004 3.9764 14.1818 4.40759 14.1818C4.83878 14.1818 5.18958 14.5004 5.18958 14.892C5.18958 15.2835 4.83878 15.6021 4.40759 15.6021ZM8.21093 14.3594V9.38406C8.21093 9.10792 8.43479 8.88406 8.71093 8.88406H10.1156C10.3917 8.88406 10.6156 9.10792 10.6156 9.38406V14.3594H8.21093ZM15.327 15.6021C14.8958 15.6021 14.545 15.2835 14.545 14.892C14.545 14.5004 14.8958 14.1818 15.327 14.1818C15.7582 14.1818 16.109 14.5004 16.109 14.892C16.109 15.2835 15.7582 15.6021 15.327 15.6021ZM18.827 14.3594H17.192C16.9423 13.6398 16.2008 13.1166 15.327 13.1166C14.4533 13.1166 13.7118 13.6398 13.4621 14.3594H11.7886V8.31885C11.7886 8.04271 11.5647 7.81885 11.2886 7.81885H7.53794C7.2618 7.81885 7.03794 8.04271 7.03794 8.31885V14.3594H6.27257C6.02288 13.6398 5.28131 13.1166 4.40759 13.1166C3.53387 13.1166 2.7923 13.6398 2.54261 14.3594H1.17299V4.94377C1.17299 4.65034 1.43586 4.41141 1.75898 4.41116L16.7524 4.3992C17.0463 4.3992 17.3235 4.50305 17.5314 4.6917C17.7395 4.88053 17.8542 5.13171 17.8542 5.39886C17.8542 5.95007 17.3603 6.39853 16.7534 6.39853H13.4615C13.1854 6.39853 12.9615 6.62239 12.9615 6.89853V10.1061C12.9615 10.3823 13.1854 10.6061 13.4615 10.6061H16.4569L18.827 11.8775V14.3594ZM15.8629 9.04094C15.8629 9.31708 15.639 9.54094 15.3629 9.54094H14.6345C14.3584 9.54094 14.1345 9.31708 14.1345 9.04094V7.96378C14.1345 7.68763 14.3584 7.46378 14.6345 7.46378H15.3629C15.639 7.46378 15.8629 7.68763 15.8629 7.96378V9.04094Z"
                  fill="#272727"
                  stroke="#272727"
                  strokeWidth="0.3"
                />
                <path
                  d="M2.22266 10.6126C2.22266 10.8888 2.44651 11.1126 2.72266 11.1126H6.1671C6.44324 11.1126 6.6671 10.8888 6.6671 10.6126V8.2793C6.6671 8.00315 6.44324 7.7793 6.1671 7.7793H2.72266C2.44651 7.7793 2.22266 8.00315 2.22266 8.2793V10.6126ZM3.70414 9.39041C3.70414 9.11427 3.928 8.89041 4.20414 8.89041H4.68562C4.96176 8.89041 5.18562 9.11427 5.18562 9.39041V9.50152C5.18562 9.77766 4.96176 10.0015 4.68562 10.0015H4.20414C3.92799 10.0015 3.70414 9.77766 3.70414 9.50152V9.39041Z"
                  fill="#272727"
                />
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
            Mobile Home
          </div>
        </label>
        <label>
          <input
            name="type"
            type="radio"
            value="land"
            onClick={(e) => {
              handleChange(e);
            }}
            checked={propertyType === "land"}
          />
          <div>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.5 11.5C13.5 11.8452 13.2202 12.125 12.875 12.125C12.0459 12.0921 12.0462 10.9078 12.875 10.875C13.2202 10.875 13.5 11.1548 13.5 11.5ZM16 11.517C15.9987 12.8387 15.223 13.6823 14.4728 14.4981C14.124 14.8774 13.7634 15.2696 13.4837 15.71C13.369 15.8906 13.17 16 12.9561 16H12.7939C12.58 16 12.381 15.8906 12.2663 15.7101C11.9867 15.2697 11.626 14.8774 11.2772 14.4981C10.527 13.6823 9.75131 12.8387 9.75003 11.5183C9.92675 7.34747 15.8241 7.35059 16 11.517ZM14.75 11.5171C14.6429 9.00609 11.1054 9.00809 11 11.5184C11.0008 12.3508 11.5288 12.925 12.1973 13.652C12.4236 13.8981 12.6543 14.1491 12.875 14.4223C13.0957 14.1491 13.3264 13.8981 13.5527 13.652C14.2212 12.925 14.7492 12.3508 14.75 11.5171ZM16 2.5V7.40625C15.967 8.2355 14.7827 8.23487 14.75 7.40625V3.90184L10.7552 7.90997C10.638 8.0275 10.479 8.09363 10.313 8.09375H10.3125C10.1468 8.09375 9.98775 8.02791 9.87053 7.91069L7.41337 5.45353L4.80638 8.06053L8.56616 11.8073C8.81066 12.0509 8.81137 12.4467 8.56772 12.6912C8.32403 12.9357 7.92831 12.9363 7.68381 12.6927L3.9225 8.94444L1.25 11.6169V13.5C1.25 14.1892 1.81075 14.75 2.5 14.75H9.6875C10.5168 14.783 10.5161 15.9673 9.6875 16H2.5C1.1215 16 0 14.8785 0 13.5V2.5C0 1.1215 1.1215 0 2.5 0H13.5C14.8785 0 16 1.1215 16 2.5ZM6.52953 4.56966L3.20988 1.25H2.5C1.81075 1.25 1.25 1.81075 1.25 2.5V9.84919L6.52953 4.56966ZM14.7073 2.17556C14.564 1.64309 14.0771 1.25 13.5 1.25H4.97762L10.3118 6.58413L14.7073 2.17556ZM14.7157 2.20881C14.7152 2.20656 14.7147 2.20431 14.7141 2.20206C14.7096 2.20922 14.7097 2.21191 14.7157 2.20881Z"
                fill="#272727"
              />
            </svg>
            Land
          </div>
        </label>
      </div>
      <div className="MessageAgentForm__screen-controls">
        <button
          className="--alt"
          type="button"
          onClick={() => {
            prevScreen({ key: screenKey });
          }}
        >
          Back
        </button>
      </div>
    </div>
  );
};

PropertyType.propTypes = {
  clientType: PropTypes.string,
  propertyType: PropTypes.string,
  setPropertyType: PropTypes.func.isRequired,
  nextScreen: PropTypes.func.isRequired,
  prevScreen: PropTypes.func.isRequired,
};

PropertyType.defaultProps = {
  clientType: undefined,
  propertyType: undefined,
  setPropertyType: () => {},
  nextScreen: () => {},
  prevScreen: () => {},
};

export default PropertyType;
