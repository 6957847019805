import PriceRangeInput from "~/components/Inputs/PriceRange";
import PropTypes from "prop-types";
import React from "react";

/**
 * PriceRange
 *
 * Price range screen. Collects users price range.
 *
 * @param {*} props
 * @returns
 */
const PriceRange = (props) => {
  const {
    claimed,
    clientType,
    nextScreen,
    prevScreen,
    setClientMinPrice,
    setClientMaxPrice,
    clientPriceRangeValue,
    setClientPriceRangeValue,
  } = props;
  const screenKey = "PriceRange";

  //set context dependent text strings
  const heading =
    clientType == "buying"
      ? "What price range are you looking to buy?"
      : "Roughly, what is your home worth?";

  return (
    <div className="MessageAgentForm__screen" key="price">
      <div className="MessageAgentForm__screen-heading">{heading}</div>
      <PriceRangeInput
        onChange={(min, max) => {
          setClientMinPrice(min);
          setClientMaxPrice(max);
        }}
        clientPriceRangeValue={clientPriceRangeValue}
        setClientPriceRangeValue={setClientPriceRangeValue}
      />
      <div className="MessageAgentForm__screen-controls">
        <button
          className="--alt"
          type="button"
          onClick={() => {
            prevScreen({ key: screenKey });
          }}
        >
          Back
        </button>
        <button
          type="button"
          onClick={() => {
            nextScreen({ key: screenKey });
          }}
        >
          Next
        </button>
      </div>
    </div>
  );
};

PriceRange.propTypes = {
  claimed: PropTypes.bool.isRequired,
  clientType: PropTypes.string,
  setClientMinPrice: PropTypes.func.isRequired,
  setClientMaxPrice: PropTypes.func.isRequired,
  nextScreen: PropTypes.func.isRequired,
  prevScreen: PropTypes.func.isRequired,
  clientPriceRangeValue: PropTypes.number.isRequired,
  setClientPriceRangeValue: PropTypes.func.isRequired,
};

PriceRange.defaultProps = {
  claimed: false,
  clientType: undefined,
  setClientMinPrice: () => {},
  setClientMaxPrice: () => {},
  nextScreen: () => {},
  prevScreen: () => {},
  clientPriceRangeValue: 0,
  setClientPriceRangeValue: () => {},
};

export default PriceRange;
