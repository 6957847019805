import React from "react";
import PropTypes from "prop-types";
import { capitalize } from "../../../../utils/TextProcessing";
import { getLeadUUID } from "~/utils/UUID";
import { css, StyleSheet } from "aphrodite";
import ContactFormSuccess from "../../Profile/Prime/ContactFormSuccess";
import { genericFunnels } from "~/config/funnel";

/**
 * Complete
 *
 * Complete screen. Displays once user has completed the wizard.
 *
 * @param {*} props
 * @returns
 */
const Complete = (props) => {
  const {
    agent,
    onRequestClose,
    claimed,
    competingProposals,
    funnel,
    useContactForm,
    otherAgents,
    isPrime,
    isNonPrime,
  } = props;

  const referrer = localStorage.getItem("referrer");
  const successMessage = () => {
    if (genericFunnels.includes(funnel)) {
      return (
        <>
          <li class="text-center">
            We will send your message to up to 4 top real estate agents. <br />{" "}
            The agents will get back to you soon.
          </li>
        </>
      );
    }
    if (competingProposals && claimed) {
      return (
        <>
          <li class="text-center">
            {capitalize(agent.display_name.split(/(\s+)/)[0])} will reach out to
            you as soon as they review your message.
          </li>
          <li class="text-center">
            We will send your message to up to 4 top real estate agents. <br />{" "}
            The agents will get back to you soon.
          </li>
        </>
      );
    } else if (competingProposals && !claimed) {
      return (
        <>
          <li class="text-center">
            We will send your message to up to 4 top real estate agents. <br />{" "}
            The agents will get back to you soon.
          </li>
        </>
      );
    }
    return (
      <>
        <li class="text-center">
          {capitalize(agent.display_name.split(/(\s+)/)[0])} will reach out to
          you as soon as they review your message.
        </li>
      </>
    );
  };

  return (
    <div className={"MessageAgentForm__complete"}>
      {useContactForm ? (
        <>
          <ContactFormSuccess
            firstName={capitalize(agent.display_name.split(/(\s+)/)[0])}
            funnel={funnel}
            messageOtherAgents={otherAgents}
            isPrime={isPrime}
            isNonPrime={isNonPrime}
          />
          <div className="MessageAgentForm__screen-controls">
            <button
              type="button"
              onClick={() => {
                onRequestClose();
              }}
            >
              Got it
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="MessageAgentForm__complete-header">
            <svg
              width="90"
              height="90"
              viewBox="0 0 90 90"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M77.9989 19.5717C76.9692 18.5402 75.2983 18.5384 74.2686 19.5674L42.6191 51.1333L31.2001 38.7311C30.2134 37.6601 28.5452 37.5906 27.4723 38.5772C26.4003 39.5639 26.3317 41.2329 27.3184 42.3049L40.5973 56.7262C41.0836 57.2547 41.7642 57.5617 42.4819 57.5766C42.5011 57.5774 42.5197 57.5774 42.5382 57.5774C43.2356 57.5774 43.9065 57.3004 44.4007 56.8079L77.9937 23.3029C79.0261 22.2741 79.0279 20.6033 77.9989 19.5717Z"
                fill="#608A78"
              />
              <path
                d="M77.3617 42.3617C75.9046 42.3617 74.7236 43.5427 74.7236 45C74.7236 61.3903 61.3903 74.7236 45 74.7236C28.6107 74.7236 15.2764 61.3903 15.2764 45C15.2764 28.6107 28.6107 15.2764 45 15.2764C46.4571 15.2764 47.6383 14.0954 47.6383 12.6383C47.6383 11.181 46.4571 10 45 10C25.7008 10 10 25.7008 10 45C10 64.2984 25.7008 80 45 80C64.2984 80 80 64.2984 80 45C80 43.5429 78.819 42.3617 77.3617 42.3617Z"
                fill="#608A78"
              />
            </svg>
            <div className="MessageAgentForm__complete-heading">
              {genericFunnels.includes(funnel)
                ? "View your Agents!"
                : "Your message has been sent!"}
            </div>
          </div>
          {genericFunnels.includes(funnel) ? (
            <>
              <ul className="MessageAgentForm__complete-list --alt text-center">
                We're fetching the best agents to help you. <br />
                <br />
                Click the button below to view them!
              </ul>
              <div
                className="MessageAgentForm__screen-controls"
                style={{ paddingTop: 16 }}
              >
                <a
                  type="button"
                  href={`${
                    process.env.TAR_ORIGIN
                  }/compare-agents/app?force_path=true&uuid=${getLeadUUID()}&experience=tv&${referrer}`}
                  target="_blank"
                >
                  <button>Click to View Agents</button>
                </a>
              </div>
            </>
          ) : (
            <>
              <div className="MessageAgentForm__complete-sub-heading">
                What’s next?
              </div>
              <ul className="MessageAgentForm__complete-list --alt">
                {successMessage()}
              </ul>
              <div className="MessageAgentForm__screen-controls">
                <button
                  type="button"
                  onClick={() => {
                    onRequestClose();
                  }}
                >
                  Got it
                </button>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 0,
    height: 500,
    width: "100%",

    "@media only screen and (min-width: 1024px)": {
      height: 650,
      width: 980,
    },
  },
});

Complete.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
};

Complete.defaultProps = {
  onRequestClose: () => {},
};

export default Complete;
