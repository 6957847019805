import { combineReducers } from "redux";
import PlacesSearchReducer from "~/redux/placesSearch";
import ServerSideRenderReducer from "~/redux/serverSideRender";
import ModalReducer from "~/redux/modal";
import ErrorReducer from "~/redux/error";
import messageReduxer from "~/redux/message";

export default combineReducers({
  placesSearch: PlacesSearchReducer,
  serverSideRender: ServerSideRenderReducer,
  modal: ModalReducer,
  error: ErrorReducer,
  message: messageReduxer,
});
