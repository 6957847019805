import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

const TextArea = (props) => {
  const {
    autoComplete,
    autoFocus,
    className,
    customErrorMessage,
    helpText,
    label,
    maxlength,
    name,
    onChange,
    pattern,
    required,
    value,
    rows,
    inputStyle,
    inputValueRestrictions,
    checkValidation,
    inputRef,
    onFocus,
    inlineFunnel,
    altActive,
  } = props;

  const [id, setID] = useState(uuidv4());
  const [active, setActive] = useState(() => {
    if (value !== undefined && value !== null && value.length >= 1) return true;
    return false;
  });
  const [internalValue, setInternalValue] = useState(value);
  const [valid, setValid] = useState(true);
  const [errors, setErrors] = useState();

  useEffect(() => {
    setInternalValue(value);
    setActive(() => {
      if (value !== undefined && value !== null && value.length >= 1)
        return true;
      return false;
    });
  }, [value]);

  useEffect(() => {
    if (inputRef?.current && checkValidation) {
      if (!inputRef.current.checkValidity()) {
        setValid(false);
        errorMessages(inputRef.current);
      } else {
        setValid(true);
        setErrors([]);
      }
    }
  }, [checkValidation]);

  /**
   * errorMessages
   *
   * adds error messages if input is invalid.
   *
   * @param {Element} el
   */
  const errorMessages = (el) => {
    let errorMessages = [];

    if (el.validity.valueMissing) {
      errorMessages.push({
        message: `${label} is required`,
        type: "valueMissing",
      });
    }

    if (el.validity.typeMismatch && pattern === null) {
      errorMessages.push({
        message: `Please enter a ${label.toLowerCase()}`,
        type: "typeMismatch",
      });
    }

    if (el.validity.patternMismatch) {
      errorMessages.push({
        message: `Please enter a valid ${label.toLowerCase()}`,
        type: "patternMismatch",
      });
    }

    errorMessages = customErrorMessage({
      errorMessages,
      el,
      pattern,
      label,
      name,
    });
    setErrors(errorMessages);
  };

  const handleBlur = (e) => {
    if (internalValue === null || internalValue.length <= 0) {
      setActive(false);
    }
    if (!e.target.validity.valid) {
      setValid(false);
      errorMessages(e.target);
    } else {
      setValid(true);
      setErrors([]);
    }
  };

  const handleFocus = (e) => {
    onFocus(e);
    if (!active) {
      setActive(true);
    }
  };

  const handleChange = (e) => {
    let inputValue = inputValueRestrictions(e.target.value);

    setInternalValue(inputValue);

    if (e.target.validity.valid) {
      setValid(true);
      setErrors([]);
    }

    onChange(inputValue);
  };

  return (
    <div className={`TextArea ${className}`}>
      <div className="TextArea__wrapper">
        {label && (
          <label
            htmlFor={id}
            className={`${inlineFunnel && !active ? "--inline-funnel" : ""} ${
              active ? (altActive ? "--inline-funnel-active" : "--active") : ""
            }`}
          >
            {label}
          </label>
        )}
        <textarea
          id={id}
          className={`${
            !valid
              ? "--invalid"
              : internalValue && internalValue.length >= 1
              ? "--valid"
              : ""
          } ${inputStyle}`}
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          maxLength={maxlength}
          name={name}
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
          pattern={pattern}
          required={required}
          value={internalValue}
          rows={rows}
          ref={inputRef}
        ></textarea>
      </div>
      {helpText && <div className="TextArea__help-text">{helpText}</div>}
      {errors && errors.length > 0 && (
        <ul className="Input__errors">
          {errors.map((error, index) => (
            <li key={index}>{error.message}</li>
          ))}
        </ul>
      )}
    </div>
  );
};

TextArea.propTypes = {
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.string,
  className: PropTypes.string,
  customErrorMessage: PropTypes.func,
  helpText: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  maxlength: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  pattern: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.string,
  rows: PropTypes.number,
  inputValueRestrictions: PropTypes.func,
  checkValidation: PropTypes.bool,
  inputRef: PropTypes.object,
  onFocus: PropTypes.func,
  altActive: PropTypes.bool,
};

TextArea.defaultProps = {
  autoComplete: "off",
  autoFocus: null,
  className: "",
  customErrorMessage: ({ errorMessages }) => {
    return errorMessages;
  },
  inputValueRestrictions: (inputValue) => {
    let newInputValue = inputValue.replace(
      /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g,
      ""
    );

    return newInputValue;
  },
  helpText: null,
  id: null,
  label: "",
  maxlength: null,
  name: null,
  onChange: () => {},
  pattern: null,
  required: null,
  value: "",
  rows: 5,
  checkValidation: false,
  inputRef: null,
  onFocus: () => {},
  altActive: false,
};

export default TextArea;
