import { css, StyleSheet } from "aphrodite";

export const ViewAgents = ({ leadUUID }) => {
  const referrer = localStorage.getItem("referrer") || "";
  if (!leadUUID) {
    return null;
  }
  return (
    <a
      className={css(styles.viewAgents)}
      href={`${process.env.TAR_ORIGIN}/compare-agents/app?force_path=true&uuid=${leadUUID}&experience=tv&referrer=${referrer}`}
    >
      <button>View your Agents</button>
    </a>
  );
};

const styles = StyleSheet.create({
  viewAgents: {
    // position: "absolute",
    // bottom: 35,
    // left: "50%",
    // transform: "translateX(-50%)",
    // textDecoration: "underline",
    // marginRight: "auto",
    // marginLeft: "auto",
  },
});
