import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";

const TwoFA = (props) => {
  const {
    pinLength,
    className,
    helpText,
    value,
    onChange,
    error,
    setError,
  } = props;

  const inputElms = useRef(new Array(pinLength));
  const [valids, setValids] = useState(new Array(pinLength).fill(false));
  const [hasBeenFocused, setHasBeenFocused] = useState(
    new Array(pinLength).fill(false)
  );
  const [errors, setErrors] = useState([]);

  const [internalValue, setInternalValue] = useState("");

  useEffect(() => {
    if (error) {
      setErrors([{ message: "Please try again." }]);
      setHasBeenFocused(new Array(pinLength).fill(false));
    } else {
      setErrors([]);
    }
  }, [error]);

  const navKeys = [
    "ArrowLeft",
    "ArrowRight",
    "ArrowUp",
    "ArrowDown",
    "Tab",
    "Shift",
    "Backspace",
  ];

  const handleChange = (e, i) => {
    let newValue = "";
    let newHasBeenFocused = hasBeenFocused;
    inputElms.current.map((e, i) => {
      const inputValue = e.value
        .trim()
        .charAt()
        .replace(/[^0-9]/g, "");
      e.value = inputValue;
      newValue = newValue + inputValue;
      if (e.value.length > 0) {
        newHasBeenFocused[i] = true;
      }
    });

    let newValids = valids;
    newValids[i] = e.target.validity.valid;
    setValids(newValids);
    setHasBeenFocused(newHasBeenFocused);

    setInternalValue(newValue);

    if (i < inputElms.current.length - 1 && e.target.value != "") {
      inputElms.current[i + 1].focus();
    }

    onChange(e, newValue);
    setError(false);
  };

  const handleKeyDown = (e, i) => {
    if (e.key == "Backspace" && e.target.value == "" && i > 0) {
      inputElms.current[i - 1].focus();
    }
    if (e.key == "ArrowLeft" && i > 0) {
      inputElms.current[i - 1].focus();
    }

    if (e.key == "ArrowRight" && i < inputElms.current.length - 1) {
      inputElms.current[i + 1].focus();
    }
  };

  const handleFocus = (e, i) => {
    if (e.target.value != "") {
      e.target.select();
    }

    if (error) {
      setError(false);
    }
  };

  const handleBlur = (e, i) => {
    let newHasBeenFocused = hasBeenFocused;
    newHasBeenFocused[i] = true;
    setHasBeenFocused(newHasBeenFocused);
  };

  const handlePaste = (e) => {
    const data = e.clipboardData
      .getData("text/plain")
      .trim()
      .slice(0, pinLength);
    let newValue = "";
    let newValids = valids;
    let newHasBeenFocused = hasBeenFocused;

    inputElms.current.map((e, i) => {
      if (data[i] != undefined) {
        newValue = newValue + data[i];
        e.value = data[i];
      } else {
        e.value = "";
      }
      if (e.value.length > 0) {
        newHasBeenFocused[i] = true;
      }
      newValids[i] = e.validity.valid;
    });
    setValids(newValids);
    setHasBeenFocused(newHasBeenFocused);
    setInternalValue(newValue);
    onChange(e, newValue);
    inputElms.current[pinLength - 1].focus();
  };

  return (
    <div className={`TwoFA ${className}`}>
      <div className="TwoFA__wrapper">
        <div className="TwoFA__grid">
          {Array.apply(null, { length: pinLength }).map((e, i) => (
            <div className="TwoFA__input" key={i}>
              <input
                className={`${
                  hasBeenFocused[i] ? (valids[i] ? "--valid" : "--invalid") : ""
                }`}
                ref={(element) => (inputElms.current[i] = element)}
                maxLength={1}
                onChange={(e) => {
                  handleChange(e, i);
                }}
                onKeyDown={(e) => {
                  handleKeyDown(e, i);
                }}
                onFocus={(e) => {
                  handleFocus(e, i);
                }}
                onBlur={(e) => {
                  handleBlur(e, i);
                }}
                required={true}
                value={value.split("")[i]}
                onPaste={handlePaste}
                type={"number"}
                inputMode={"numeric"}
                pattern="\d*"
                min={0}
                max={9}
              />
            </div>
          ))}
        </div>
        {helpText != "" && <div className="TwoFA__help-text">{helpText}</div>}
        {errors && (
          <ul className="TwoFA__errors">
            {errors.map((error, index) => (
              <li key={index}>{error.message}</li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

TwoFA.propTypes = {
  className: PropTypes.string,
  pinLength: PropTypes.number,
  helpText: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  setError: PropTypes.func,
};

TwoFA.defaultProps = {
  className: "",
  pinLength: 6,
  helpText: "",
  value: "",
  onChange: (e, value) => {},
  error: false,
  setError: () => {},
};

export default TwoFA;
