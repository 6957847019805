import styles from "./PriceRange.module.scss";

import { useState, useEffect } from "react";
import PropTypes from "prop-types";

const PriceRange = (props) => {
  const {
    onChange,
    clientPriceRangeValue,
    setClientPriceRangeValue,
    priceRangeHeadingClassName,
    containerClassName,
  } = props;
  const [maxHeader, setMaxHeader] = useState(350000);
  const [minHeader, setMinHeader] = useState(300000);
  const [completed, setCompleted] = useState(
    (clientPriceRangeValue / 28) * 100
  );

  // set the inital
  onChange(minHeader, maxHeader);

  useEffect(() => {
    setValue(clientPriceRangeValue);
  }, []);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const setValue = (inputValue) => {
    const input = Number(inputValue);
    if (input >= 18 && input < 26) {
      const offset = input - 18;
      setMaxHeader((offset + 1) * 250000 + 1000000);
      setMinHeader(offset * 250000 + 1000000);
    } else if (input >= 26) {
      const offset = input - 26;
      setMaxHeader((offset + 1) * 1000000 + 3000000);
      setMinHeader(offset * 1000000 + 3000000);
    } else {
      setMaxHeader((input + 2) * 50000);
      setMinHeader((input + 1) * 50000);
    }

    setClientPriceRangeValue(input);
    onChange(minHeader, maxHeader);
    setCompleted(((input - 0) / (28 - 0)) * 100);
  };

  const magnitude = (value) => {
    const order = Math.floor(Math.log10(Math.abs(value)));
    return Math.pow(10, order);
  };

  const humanize = (value) => {
    const magnitudeValue = magnitude(value);
    let humanizedValue;
    switch (magnitudeValue) {
      case 100000:
        humanizedValue = `${value / 1000}K`;
        break;
      case 1000000:
        humanizedValue = `${value / 1000000}M`;
        break;
      default:
        humanizedValue = `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        break;
    }
    return `$${humanizedValue}`;
  };

  const heading = () => {
    if (clientPriceRangeValue === 0) {
      return (
        <>
          <span>Under {humanize(100000)}</span>
        </>
      );
    } else if (clientPriceRangeValue === 28) {
      return (
        <>
          <span>{humanize(5000000)}+</span>
        </>
      );
    } else {
      return (
        <>
          <span>{humanize(minHeader)}</span> -{" "}
          <span>{humanize(maxHeader)}</span>
        </>
      );
    }
  };

  return (
    <div className={styles["PriceRange"] + " " + containerClassName}>
      <div className={styles["PriceRange__heading-container"]}>
        <button
          className={styles["PriceRange__control"]}
          onClick={() => {
            if (clientPriceRangeValue > 0) {
              const newValue = clientPriceRangeValue - 1;
              setClientPriceRangeValue(newValue);
              setValue(newValue);
            }
          }}
        >
          -
        </button>
        <div
          className={
            styles["PriceRange__heading"] + " " + priceRangeHeadingClassName
          }
        >
          {heading()}
        </div>
        <button
          className={styles["PriceRange__control"]}
          onClick={() => {
            if (clientPriceRangeValue < 28) {
              const newValue = clientPriceRangeValue + 1;
              setClientPriceRangeValue(newValue);
              setValue(newValue);
            }
          }}
        >
          +
        </button>
      </div>
      <div className={styles["PriceRange__container"]}>
        <input
          type="range"
          min="0"
          max="28"
          step="1"
          onChange={(e) => {
            handleChange(e);
          }}
          value={clientPriceRangeValue}
        />
        <div
          className={styles["PriceRange__track"]}
          style={{
            background: `linear-gradient(to right, rgba(234, 88, 12, 1) 0%, rgba(234, 88, 12, 1) ${completed}%, rgba(234, 88, 12, 0.2) ${completed}%, rgba(234, 88, 12, 0.2) 100%)`,
          }}
        ></div>
      </div>

      <div className={styles["PriceRange__labels"]}>
        <div>{humanize(100000)}</div>
        <div>{humanize(5000000)}+</div>
      </div>
    </div>
  );
};

PriceRange.propTypes = {
  onChange: PropTypes.func.isRequired,
  clientPriceRangeValue: PropTypes.number.isRequired,
  setClientPriceRangeValue: PropTypes.func.isRequired,
};

PriceRange.defaultProps = {
  onChange: () => {},
  clientPriceRangeValue: 0,
  setClientPriceRangeValue: () => {},
};

export default PriceRange;
