import API, { generateApiUrl } from "~/config/api";
import Helpers from "~/config/helpers";
import { setLeadUUID, getLeadUUID } from "~/utils/UUID";
import { parseCookies } from "nookies";
import queryString from "query-string";
import { queryParams as analyticsQueryParams } from "./Analytics";
import { v4 as uuidv4 } from "uuid";

const WHITELISTED_AD_PARAMETERS = {
  type_id: true,
  origin: true,
  funnel: true,
  github_revision: true,
  referrer: true,
  gclid: true,
  msclkid: true,
  fbclid: true,
  origin_path: true,
};

export const setExpid = (params) => {
  const expid = "two-page-funnel";
  if (expid) {
    window.localStorage.setItem("expid", expid);
  }
};

export const setOriginPath = (path) => {
  if (!window.localStorage.getItem("twopage_funnel_originPath")) {
    window.localStorage.setItem("twopage_funnel_originPath", path);
  }
};

export const leadGen = ({ lead, user }) => {
  let cookies = parseCookies();
  let uuid = cookies.lead_uuid;
  if (!uuid) {
    uuid = setLeadUUID();
  }
  lead.uuid = uuid;
  if (!lead.country) {
    lead.country = "US";
  }
  let ad_data = {};
  if (process.browser) {
    let cookies = parseCookies();

    let queryParams = queryString.parse(analyticsQueryParams());

    if (queryParams && Object.keys(queryParams).length === 0) {
      queryParams = JSON.parse(cookies.utm_params);
    }

    if (!queryParams) {
      queryParams = {};
    }

    ad_data = queryParams;
  }
  return fetch(
    API.leadGen({}),
    API.POST_CONFIG({ data: { lead: lead, user, ad_data } })
  )
    .then(Helpers.checkStatus)
    .then(Helpers.parseJSON)
    .then((resp) => {
      return resp;
    });
};

export function saveAdData(params) {
  // const urlParams = new URLSearchParams(querystring);
  // const entries = urlParams.entries();
  // const params = paramsToObject(entries);
  const paramKeys = Object.keys(params);

  let savedAdData = window.localStorage.getItem("twopage_funnel_ad_data");

  if (!savedAdData) {
    savedAdData = {
      utm_host: "realestateagents",
    };
  } else {
    savedAdData = JSON.parse(savedAdData);
  }

  for (let i = 0; i < paramKeys.length; i++) {
    const curKey = paramKeys[i];
    if (curKey.includes("utm_") || WHITELISTED_AD_PARAMETERS[curKey]) {
      savedAdData[curKey] = params[curKey];
    }
  }
  window.localStorage.setItem(
    "twopage_funnel_ad_data",
    JSON.stringify(savedAdData)
  );
}

export function getAdData() {
  const adData = window.localStorage.getItem("twopage_funnel_ad_data");
  let adDataJson = {};
  if (adData) {
    adDataJson = JSON.parse(adData);
  }
  const expid = window.localStorage.getItem("expid");
  if (expid) {
    adDataJson.utm_expid = expid;
  }

  const originPath = window.localStorage.getItem("twopage_funnel_originPath");
  if (originPath) {
    adDataJson.origin_path = originPath;
  }

  return adDataJson;
}

export const sendGeocode = ({ google_response, geocode_input, lead_type }) => {
  const geocode_request_uuid = uuidv4();
  window.localStorage.setItem("geocode_request_uuid", geocode_request_uuid);
  const data = {
    google_response,
    lead_type: lead_type,
    geocode_input: geocode_input,
    geocode_request_uuid: geocode_request_uuid,
  };
  return fetch(
    generateApiUrl("funnel/record_geocode"),
    API.POST_CONFIG({ data })
  );
};

export function leadSubmission({
  otherLead,
  leadType,
  mode,
  user,
  lead,
  funnel_name,
} = {}) {
  const _leadType = leadType;
  const _leadUUID = otherLead ? uuidv4() : getLeadUUID();
  const adData = getAdData();
  adData.referrer = window.localStorage.getItem("referrer");
  const data = {
    mode,
    ad_data: adData,
    user,
    funnel_name,
    lead: {
      ...lead,
      geocode_request_uuid: window.localStorage.getItem("geocode_request_uuid"),
      uuid: _leadUUID,
    },
  };

  data.signup02_data = {
    ...data.lead,
  };

  data.page = window.location.href.replace(window.location.origin, "");

  const config = API.POST_CONFIG({ data });

  saveLeadUUID({ leadType: _leadType });

  if (!otherLead) {
    window.localStorage.setItem("lastLeadType", _leadType);
    if (_leadType === "seller") {
      window.localStorage.setItem("last-seller-uuid", _leadUUID);
    } else if (_leadType === "buyer") {
      window.localStorage.setItem("last-buyer-uuid", _leadUUID);
    }
  }

  const url = generateApiUrl("funnel/submit");

  return fetch(url, config);
}

function saveLeadUUID({ leadType }) {
  const leadTypeKey = `completed_${leadType}`;
  const _leadUUID = getLeadUUID();

  let completedLeads = window.localStorage.getItem(leadTypeKey);

  if (completedLeads) {
    completedLeads = JSON.parse(completedLeads);
    completedLeads.push(_leadUUID);
  } else {
    completedLeads = [_leadUUID];
  }

  window.localStorage.setItem(leadTypeKey, JSON.stringify(completedLeads));
}

export const contactRequest = ({
  lead,
  user,
  consent,
  ad,
  inline_message_request,
  new_lead_type,
}) => {
  const lead_uuid = getLeadUUID();
  lead.uuid = lead_uuid;
  if (!lead.country) {
    lead.country = "US";
  }
  let ad_data = {};
  if (process.browser) {
    let cookies = parseCookies();

    let queryParams = queryString.parse(analyticsQueryParams());

    if (
      queryParams &&
      Object.keys(queryParams).length === 0 &&
      cookies.utm_params
    ) {
      queryParams = JSON.parse(cookies.utm_params);
    }

    if (!queryParams) {
      queryParams = {};
    }

    ad_data = queryParams;

    ad_data.origin_path = window.location.pathname;
    ad_data.referrer = localStorage.getItem("referrer");
    ad_data = { ...ad_data, ...ad };
  }
  return fetch(
    inline_message_request
      ? API.inlineMessageRequest({})
      : API.contactRequest({}),
    API.POST_CONFIG({
      data: { lead: lead, user, ad_data, consent, lead_type: new_lead_type },
    })
  )
    .then(Helpers.checkStatus)
    .then(Helpers.parseJSON)
    .then((resp) => {
      return resp;
    });
};
