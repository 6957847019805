/**
 * Polls a promise forever until it is resolved successfully.
 * @param {Promise} promise to poll.
 * @param {Number} delay between polling.
 * @returns {Promise} Resolved promise.
 */
const poll = (promise, delay) => {
  return progressivePolling(promise, delay, false);
};

/**
 * Polls a promise progressivly forever until it is resolved successfully.
 * @param {Promise} promise Promise to poll.
 * @param {Number} delay Inital delay between polling.
 * @param {Boolean=} progressive Slowly increases delay between loops.
 * @param {Number} maxDelay Max delay when progressive polling.
 * @returns {Promise} Resolved promise.
 */
export const progressivePolling = (
  promise,
  delay,
  progressive = true,
  maxDelay = -1
) => {
  let loop = 1;
  return new Promise((resolve) => {
    (async function waitForPoll() {
      let result;
      if (progressive && loop > 1) {
        const nextDelay = delay * (loop / 2);
        if (nextDelay <= maxDelay || maxDelay <= 0) {
          delay = nextDelay;
        }
      }
      try {
        result = await promise();
        resolve(result);
      } catch {
        loop += 1;
        setTimeout(waitForPoll, delay);
      }
    })();
  });
};

export default poll;
